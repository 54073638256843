<template>
  <div class="card">
    <div class="card-header">
      <p class="card-header-title">
        <template v-if="sessionExpired"><translate>Session expirée</translate></template>
        <template v-else><translate>Connexion requise</translate></template>
      </p>
      <p class="card-header-icon">
        <span class="icon">
          <i class="fas fa-user-lock"></i>
        </span>
      </p>
    </div>
    <div class="card-content">
      <div class="notification is-danger" v-if="error">
        <button class="delete" @click="error = false"></button>
        {{ errorMessage }}
      </div>

      <div class="content">
        <p v-if="sessionExpired">
          <translate>Votre session a expiré.</translate><br />
          <strong><translate>Vos données ne sont pas perdues.</translate></strong
          ><br />
          <translate>Reconnectez-vous pour reprendre votre activité.</translate>
        </p>
        <p v-else>
          <translate>Connectez-vous pour accéder à Alma PoS.</translate><br /><br />
          <translate>L'identifiant et le mot de passe sont ceux de votre compte Alma.</translate>
        </p>

        <form @submit.prevent="login">
          <div class="field">
            <label class="label" for="email" v-translate>Email</label>
            <div class="control has-icons-left">
              <input
                class="input"
                id="email"
                placeholder="alice.dubois@gmail.com"
                required
                type="email"
                v-model="email"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
            </div>
          </div>
          <div class="field">
            <label class="label" for="password" v-translate>Mot de passe</label>
            <div class="control has-icons-left">
              <input
                class="input"
                id="password"
                placeholder="password"
                required
                type="password"
                v-model="password"
              />
              <span class="icon is-small is-left">
                <i class="fas fa-lock"></i>
              </span>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <button
                :class="{
                  button: true,
                  'is-primary': true,
                  'is-loading': loading,
                }"
                type="submit"
              >
                <translate>Se connecter</translate>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <footer class="card-footer">
      <a :href="register_url" class="card-footer-item" v-translate>Créer un compte</a>
      <a :href="forgot_password_url" class="card-footer-item" v-translate>Mot de passe oublié ?</a>
    </footer>
  </div>
</template>

<script>
import { currentUser } from "@/lib/user";
import { WEBSITE_URL } from "@/lib/config";
import { DASHBOARD_URL } from "@/lib/config";

export default {
  name: "LoginCard",
  data() {
    const { email } = currentUser();
    return {
      email: email,
      password: null,
      error: false,
      errorMessage: "",
      loading: false,
    };
  },

  methods: {
    async login() {
      const { email, password } = this;

      this.loading = true;
      try {
        await this.$store.dispatch("login", { email, password });
        this.error = false;
        this.errorMessage = "";
        this.$emit("logged-in");
      } catch (e) {
        this.error = true;
        this.errorMessage =
          e.response.data.message || this.gettext("Une erreur est survenue, merci de réessayer");
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    sessionExpired() {
      return this.$store.state.sessionExpired;
    },

    register_url() {
      return `${WEBSITE_URL}/fr-FR/creer-un-compte`;
    },

    forgot_password_url() {
      return `${DASHBOARD_URL}/forgot-password`;
    },
  },
};
</script>

<style scoped></style>
