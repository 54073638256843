import axios from "axios";
import store from "../../store/index";
import { POS_URL, API_URL } from "../config";
import { ExpiredSessionError, InternalServerError, ValidationError } from "./errors";

axios.defaults.withCredentials = true;

class HttpClient {
  constructor(baseUrl, options) {
    this._client = axios.create({ ...options, baseURL: baseUrl });
    this._client.defaults.withCredentials = true;

    this._setupInterceptors();
  }

  _setupInterceptors() {
    this._client.interceptors.response.use(undefined, HttpClient._interceptExpiredSession);
    this._client.interceptors.request.use(HttpClient._injectCSRFToken);
  }

  _request(verb, args) {
    return this._client[verb].apply(this._client, args);
  }

  static async _interceptExpiredSession(error) {
    if (error.response.status === 401) {
      await store.dispatch("handleExpiredSession");
      return Promise.reject(new ExpiredSessionError());
    } else if (error.response.status === 400) {
      return Promise.reject(new ValidationError(error.response.data.errors));
    } else if (error.response.status >= 500) {
      return Promise.reject(new InternalServerError());
    }

    return Promise.reject(error);
  }

  static _injectCSRFToken(request) {
    if (store.state.user && request.method !== "get") {
      request.data = {
        ...request.data,
        csrf_token: store.state.user.csrf_token,
      };
    }

    return request;
  }

  get() {
    return this._request("get", arguments);
  }

  post() {
    return this._request("post", arguments);
  }

  put() {
    return this._request("put", arguments);
  }

  patch() {
    return this._request("patch", arguments);
  }

  delete() {
    return this._request("delete", arguments);
  }
}

class PoSClient extends HttpClient {
  constructor(options) {
    super(POS_URL, options);
  }

  validatePhone(phone) {
    return this.get("/validate-phone", { params: { phone } });
  }
}

export const posClient = new PoSClient();
export const apiClient = new HttpClient(`${API_URL}/v1`);
export const apiClientV2 = new HttpClient(`${API_URL}/v2`);
