<template>
  <Modal :canCancel="true" @cancel="$emit('cancel')" :requireConfirmation="true" @confirm="$emit('confirm')">
    <template v-slot:title v-translate>Pièce d'identité</template>
    <template v-slot:cancel-label v-translate>Annuler</template>
    <template v-slot:ok-label v-translate>Continuer</template>
    <template v-slot:confirm-label v-translate
      >Je confirme avoir copié la pièce d'identité du client</template
    >

    <p>
      <translate>Merci d'effectuer une copie de la pièce d'identité du client.</translate>
      <br />
      <translate>Pièces acceptées :</translate>
    </p>

    <ul>
      <li v-translate>Carte nationale d'identité française ou européenne</li>
      <li v-translate>Passeport français ou européen</li>
      <li v-translate>Titre de séjour</li>
    </ul>

    <p>
      <b v-translate>Sans l'une de ces pièces, vous devez refuser ce paiement.</b>
    </p>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "RequestIdCopyModal",
  components: { Modal },
};
</script>

<style scoped></style>
