import { apiClient as api, apiClientV2 as apiV2, posClient as pos } from "./http/clients";

export function createPayment(customer, payment, order, return_url, customer_cancel_url, target, locale) {
  const { first_name, last_name, email, phone, business_id_number } = customer;
  delete customer.business_id_number;
  const paymentData = {
    payment: {
      ...payment,
      return_url,
      customer_cancel_url,
      origin: `pos_${target}`,
      billing_address: customer,
      locale: locale,
    },
    customer: {
      first_name,
      last_name,
      email,
      phone,
      business_id_number,
      addresses: [customer],
    },
    order,
  };
  return api.post("/payments", paymentData).then((response) => response.data);
}
export function getPayment(id) {
  return api.get(`/payments/${id}`).then((response) => response.data);
}

export function sendPaymentCode(payment) {
  return apiV2
    .post(`/kyc/phone-number-verification/${payment.id}/send-security-code`)
    .then((response) => response.data);
}

export function verifyPaymentCode(payment, code) {
  return apiV2
    .post(`/kyc/phone-number-verification/${payment.id}/verify-code`, { code })
    .then((response) => response.data);
}

export function sendPaymentToSMS(payment) {
  return pos.post(`/payments/${payment.id}/send-sms`).then((response) => response.data);
}

export function sendPaymentToTerminal(payment) {
  return pos.post(`/payments/${payment.id}/send-to-terminal`).then((response) => response.data);
}

export function getEligibility(
  purchase_amount,
  installments_count,
  deferred_months,
  deferred_days,
  customer_fee_variable
) {
  return api
    .post("payments/eligibility", {
      payment: {
        purchase_amount,
        installments_count,
        deferred_months,
        deferred_days,
        customer_fee_variable,
        origin: "pos_device",
      },
    })
    .then((response) => response.data);
}

export function getActiveFeePlans() {
  return api
    .get("me/fee-plans?kind=pos&only=all&deferred=true")
    .then((resp) => resp.data.filter((fp) => fp.allowed));
}

export function getPayments(external_ids) {
  const params = {};
  if (external_ids) {
    if (Array.isArray(external_ids)) {
      params["ids"] = external_ids.join(",");
    } else {
      params["ids"] = external_ids;
    }
  }
  return api
    .get("pos/payments-poll", { params: params })
    .then((response) => response.data)
    .catch((error) => {
      const { response } = error;
      return response;
    });
}

/**
 * Get all payments from the specified range of date
 *
 * @param {number} beginDate - timestamp, the backend will take beginning of the day
 * @param {number} endDate - timestamp, the backend will take the end of the day
 * @returns list of payments in between those two dates
 */
export function getPaymentsFromDate(beginDate, endDate) {
  if (!endDate) {
    endDate = beginDate; // The backend will take the end of days itself.
  }
  return api.get(`pos/payments-poll?start=${beginDate}&end=${endDate}`).then((response) => response.data);
}

export function requestDailyPaymentsExport() {
  return api
    .post("data-exports", {
      type: "pos_export",
    })
    .then((response) => response.data);
}

export function getDailyPaymentsExport(id) {
  return api.get("data-exports/" + id).then((response) => response.data);
}

export function prettifySIREN(value) {
  value = value.replace(/ /g, "");
  value = (function addSpaces(value) {
    if (value.length <= 3) {
      return value;
    }
    return value.substring(0, 3) + " " + addSpaces(value.substring(3));
  })(value);

  return value;
}

export function validateSIREN(value) {
  return /^\d{3} \d{3} \d{3}$/.test(value);
}
