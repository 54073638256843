import Vue from "vue";
import Vuex from "vuex";
import {
  fetchUserData,
  currentUser,
  login,
  logout,
  storeLoggedUser,
  removeLoggedUser,
  switchAccount,
  updateServerLocale,
} from "@/lib/user";
import {
  getActiveFeePlans,
  getEligibility,
  getPayments,
  requestDailyPaymentsExport,
  getDailyPaymentsExport,
} from "@/lib/payments";
import Zendesk from "@dansmaculotte/vue-zendesk";
import Hotjar from "vue-hotjar";
import {
  DEFAULT_LOCALE,
  FULL_POLLING_DELAY,
  HOTJAR_POS_ID,
  POLLING_EXPORT_MAX_DELAY,
  POLLING_PAYMENTS_MIN_DELAY,
  POLLING_PAYMENTS_MAX_DELAY,
  POLLING_TICKS,
} from "@/lib/config";
import { getZendeskToken } from "@/lib/user";
import moment from "moment";
import { extendValidationTemplate } from "../main";

Vue.use(Vuex);

// Configure Zendesk by setting them here.
// cf. https://developer.zendesk.com/embeddables/docs/widget/settings
// Or ... let the ZenDesk users configure everything, from their dashboard.
const ZENDESK_WIDGET_SETTINGS = {
  webWidget: {},
  authenticate: {
    chat: {
      jwtFn: (callback) => {
        getZendeskToken().then((token) => {
          callback(token);
        });
      },
    },
  },
};

const store = new Vuex.Store({
  state: {
    sessionExpired: false,
    user: null,
    payments: [],
    showPayments: false,
    currentPayment: {
      installments_count: 3,
      order: {},
      custom_data: {},
      customer_fee_variable: undefined,
    },
    currentCustomer: {},
    activeFeePlans: [],
    allPlans: [],
    currentPlan: null,
    currentTerminal: null,
    currentTerminalProvider: null,
    currentTerminalRef: null,
    paymentsExportRequested: false,
    currentPaymentsExport: null,
    timer: null,
    lastFullPaymentsPoll: null,
  },

  mutations: {
    updateUser(state, userData) {
      state.user = { ...state.user, ...userData };
      state.enableB2BPayment = state.user.merchant.enable_b2b_on_pos;

      if (state.user.terminals) {
        state.user.terminals = state.user.terminals.sort(
          (a, b) => (a.provider_reference > b.provider_reference && 1) || -1
        );
      }
      state.currentPayment.installments_count =
        state.user && state.user.merchant && state.user.merchant.default_installments_count
          ? state.user.merchant.default_installments_count
          : 3;
      state.sessionExpired = false;
      storeLoggedUser(state.user);
    },

    removeUser(state) {
      state.user = null;
      removeLoggedUser();
    },

    setSessionExpired(state, value) {
      state.sessionExpired = value;
    },

    updatePayments(state, payments) {
      Vue.set(state, "payments", [...payments]);
    },

    setShowPayments(state, value) {
      state.showPayments = value;
    },

    /**
     *
     * @param {{}} state
     * @param {{}} paymentData
     */
    updateCurrentPayment(state, paymentData) {
      state.currentPayment = { ...state.currentPayment, ...paymentData };
    },

    updateCurrentCustomer(state, customerData) {
      state.currentCustomer = { ...state.currentCustomer, ...customerData };
    },

    setActiveFeePlans(state, plans) {
      state.activeFeePlans = plans.sort((a, b) => a.installments_count - b.installments_count);
    },

    updateAllPlans(state, plans) {
      state.allPlans = plans.sort((a, b) => a.installments_count - b.installments_count);
    },

    updateCurrentPlan(state, planData) {
      state.currentPlan = { ...state.currentPlan, ...planData };
    },

    resetCurrentPayment(state) {
      state.currentPayment = {
        order: {},
        installments_count: state.user.merchant.default_installments_count,
      };
      state.currentCustomer = {};
      if (state.user && state.user.merchant && state.user.merchant.customer_choose_installments) {
        state.currentPayment.installments_count = null;
      } else if (state.user && state.user.merchant && state.user.merchant.default_installments_count) {
        state.currentPayment.installments_count = state.user.merchant.default_installments_count;
      } else {
        state.currentPayment.installments_count = 3;
      }
    },

    resetCurrentPlan(state) {
      state.currentPlan = null;
    },

    /**
     * setting terminal to be used and store it in localstorage for future uses
     * @param {{}} state
     * @param {{}} terminal
     */
    setCurrentTerminal(state, terminal) {
      state.currentTerminalProvider = terminal.provider;
      state.currentTerminalRef = terminal.provider_reference;
      state.currentTerminal = terminal;

      localStorage.setItem("lastTerminal", terminal.provider_reference);
    },

    /**
     * If a terminal was already used, check if it is in the current user's list
     * and set it. Otherwise, pick the first one.
     * @param {{}} state
     */
    loadCurrentTerminal(state) {
      if (!state.user.terminals.length) return;

      const terminalReference = localStorage.getItem("lastTerminal");
      let found = state.user.terminals.find((term) => term.provider_reference === terminalReference);
      if (!found) {
        found = state.user.terminals[0];
      }
      state.currentTerminal = found;
      state.currentTerminalProvider = found.provider;
      state.currentTerminalRef = found.provider_reference;
      this.commit("setCurrentTerminal", found);
    },

    setPaymentsExportRequested(state, requested) {
      state.paymentsExportRequested = requested;
    },

    setCurrentPaymentsExport(state, paymentsExport) {
      state.currentPaymentsExport = paymentsExport;
    },

    setLocale(state, locale) {
      state.user.locale = locale;
    },

    setTimer(state, timer) {
      state.timer = timer;
    },

    setLastFullPaymentsPoll(state, timestamp) {
      state.lastFullPaymentsPoll = timestamp;
    },
  },

  actions: {
    setLocale(_, locale) {
      Vue.config.language = locale || DEFAULT_LOCALE;
      moment.locale(Vue.config.language);
      extendValidationTemplate();
    },

    changeLocale(context, locale) {
      if (context.state.user) updateServerLocale(context.state.user.id, locale);
      Vue.$cookies.set("alma_locale", locale);
      context.commit("setLocale", locale);
      context.dispatch("setLocale", locale);
    },

    async loadUser(context) {
      const user = currentUser();
      let data;

      try {
        data = await fetchUserData();
      } catch (e) {
        return;
      }

      const userData = { ...user, ...data };

      if (userData.merchant.use_new_pos_ui) {
        window.location.replace(window.AlmaConfig.pos_front_url);
      }

      await context.dispatch("updateUser", userData);
      await context.dispatch("loadActiveFeePlans");
    },

    async updateUser(context, userData) {
      context.commit("updateUser", userData);

      // If user has some terminals, select the first one as default
      // if none were selected before
      if (userData.terminals.length > 0 && !this.state.currentTerminal) {
        await context.commit("loadCurrentTerminal");
        await context.commit("updateCurrentPayment", { fee_plan_type: "pnx" });
      }

      await context.dispatch("useZendeskPlugin", userData);
      await context.dispatch("useHotjarPlugin", userData);
      await context.dispatch("setLocale", userData.locale);
      // Get payments
      await context.dispatch("pollAllPayments");
    },

    async login(context, user) {
      let userData;
      try {
        userData = await login(user);
      } catch (e) {
        context.commit("removeUser");
        throw e;
      }

      if (userData.merchant.use_new_pos_ui) {
        window.location.replace(window.AlmaConfig.pos_front_url);
      }

      await context.dispatch("updateUser", userData);
      await context.dispatch("loadActiveFeePlans");
    },

    async switchAccount(context, merchantId) {
      await context.dispatch("resetPayment");
      await switchAccount(merchantId);
      await context.dispatch("loadUser");
    },

    startPollTimer(context) {
      // Set the timer to 5 sec
      var timer = setInterval(() => {
        context.dispatch("pollRoutine");
      }, POLLING_TICKS);
      context.commit("setTimer", timer);
    },

    stopPollTimer(context) {
      clearInterval(context.state.timer);
      context.commit("setTimer", null);
    },

    async pollRoutine(context) {
      // If we are not connected do nothing
      if (context.state.user == null) return;
      var now = Date.now() / 1000;

      //displaying survey poll only if a payment has been made in the last 5 minutes
      //and if the user is not logged as another user, so if the real_user does not exist
      //this will not be shown at every poll because this script add a cookie to the browser
      //still use moment because it's a quick feature to get some feedback on the old POS
      if (
        !context.state.user.real_user &&
        context.state.payments.length > 0 &&
        moment
          .unix(context.state.payments[context.state.payments.length - 1].created)
          .utc()
          .isAfter(moment().subtract(5, "minutes"))
      ) {
        const delightedScript = document.createElement("script");
        delightedScript.setAttribute("type", "text/javascript");
        delightedScript.text = `!function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],s=0;s<i.length;s++){var c=i[s];a[c]=a[c]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(c)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"rXrBeOrTquaOOGkq","delightedNps"); delightedNps.survey({ email: "${context.state.user.email}",properties: { locale: "${context.state.user.locale}"}});`;
        document.body.appendChild(delightedScript);
      }

      // If the last time we got a full update was 2 minutes ago then get a full update
      if (now > context.state.lastFullPaymentsPoll + FULL_POLLING_DELAY) {
        context.dispatch("pollAllPayments");
      }
      // Else check if we have something to poll
      else {
        var ids = [];
        var pollPayment = false;
        for (const key in context.state.payments) {
          const payment = context.state.payments[key];
          if (
            ["not_ready", "not_started", "scored_yes", "scored_no", "scored_maybe"].includes(payment.state)
          ) {
            ids.push(payment.id);
            // If we have a payment that is older than 40 sec but not waiting for 10 min
            // trigger the polling system
            if (
              payment.created + POLLING_PAYMENTS_MIN_DELAY < now &&
              payment.created + POLLING_PAYMENTS_MAX_DELAY > now
            ) {
              pollPayment = true;
            }
          }
        }

        if (pollPayment && ids.length > 0) {
          var res = await getPayments(ids);
          if (res.status == 403) {
            context.dispatch("stopPollTimer");
            return;
          }
          var payments = res.data;
          var statePayments = context.state.payments;

          for (const key in payments) {
            var payment = payments[key];

            var index = statePayments.findIndex((element) => {
              if (element.id == payment.id) return true;
            });
            if (index >= 0) {
              statePayments[index] = payment;
            }
          }
          context.commit("updatePayments", statePayments);
        }
      }

      // Check if we have to poll the data export api
      if (context.state.paymentsExportRequested && context.state.currentPaymentsExport) {
        if (now < context.state.currentPaymentsExport.created + POLLING_EXPORT_MAX_DELAY) {
          var paymentsExport = await getDailyPaymentsExport(context.state.currentPaymentsExport.id);
          if (paymentsExport.complete) {
            context.commit("setPaymentsExportRequested", false);
            context.commit("setCurrentPaymentsExport", paymentsExport);
          }
        } else {
          context.commit("setPaymentsExportRequested", false);
        }
      }
    },

    async pollAllPayments(context) {
      var res = await getPayments();
      if (res.status == 403) {
        context.dispatch("stopPollTimer");
        return;
      }
      var payments = res.data;
      var now = Date.now() / 1000;

      context.commit("updatePayments", payments);
      context.commit("setLastFullPaymentsPoll", now);
    },

    async logout(context) {
      try {
        await logout();
      } finally {
        context.commit("removeUser");
        context.commit("setSessionExpired", false);
      }
    },

    handleExpiredSession(context) {
      context.commit("setSessionExpired", true);
      context.commit("removeUser");
    },

    updatePayments(context, payments) {
      context.commit("updatePayments", payments);
    },

    toggleShowPayments(context) {
      context.commit("setShowPayments", !context.state.showPayments);
    },

    async generatePaymentsExport(context) {
      context.commit("setPaymentsExportRequested", true);
      context.commit("setCurrentPaymentsExport", null);
      let exp = await requestDailyPaymentsExport();
      if (exp) {
        context.commit("setCurrentPaymentsExport", exp);
      } else {
        context.commit("setPaymentsExportRequested", false);
      }
    },

    async resetPayment({ commit, dispatch }) {
      commit("resetCurrentPayment");
      commit("resetCurrentPlan");
      await dispatch("resetFeePlanType");
    },

    resetFeePlanType({ commit, getters }) {
      if (getters.deferredPlans.length === 0) {
        // If we don't have any deferred plan, fee_plan_type is always `pnx`
        commit("updateCurrentPayment", { fee_plan_type: "pnx" });
      } else if (getters.pnxPlans.length === 0) {
        // If we don't have any pnx plan, fee_plan_type is always `deferred`
        commit("updateCurrentPayment", { fee_plan_type: "deferred", installments_count: 1 });
      } else {
        // Otherwise, fee_plan_type is unknown until merchant clicks on the FeePlanTypeSwitch
        commit("updateCurrentPayment", { fee_plan_type: null });
      }
    },

    async loadActiveFeePlans({ commit, dispatch }) {
      const feePlans = await getActiveFeePlans();
      commit("setActiveFeePlans", feePlans);

      await dispatch("resetFeePlanType");
    },

    async updateCurrentPayment({ commit, state }, paymentData) {
      commit("updateCurrentPayment", paymentData);

      const updatingAmount =
        paymentData.purchase_amount_input && paymentData.installments_count === undefined;
      const updatingInstallmentsCount = paymentData.installments_count !== undefined;
      const updatingFeePlanType = paymentData.fee_plan_type !== undefined;
      const updatingDeferred =
        paymentData.deferred_months !== undefined || paymentData.deferred_days !== undefined;
      const updatingApplyCustomerFees = paymentData.hasOwnProperty("customer_fee_variable");

      const cp = state.currentPayment;

      if (updatingAmount || updatingFeePlanType || updatingDeferred || updatingApplyCustomerFees) {
        let installmentsCounts = [1];
        if (cp.fee_plan_type === "pnx") {
          // Only pnx can have multiple counts - for now
          installmentsCounts = state.activeFeePlans
            .filter((fp) => !fp.deferred_months && !fp.deferred_days)
            .map((fp) => fp.installments_count);
        }
        const plans = await getEligibility(
          cp.purchase_amount,
          installmentsCounts,
          cp.deferred_months,
          cp.deferred_days,
          cp.customer_fee_variable
        );
        commit("updateAllPlans", plans);
      }

      if (
        updatingAmount ||
        updatingInstallmentsCount ||
        updatingFeePlanType ||
        updatingDeferred ||
        updatingApplyCustomerFees
      ) {
        commit(
          "updateCurrentPlan",
          state.allPlans.find(
            (p) =>
              p.installments_count === cp.installments_count &&
              Number(p.deferred_days || null) === Number(cp.deferred_days || null) &&
              Number(p.deferred_months || null) === Number(cp.deferred_months || null)
          )
        );
      } else {
        commit("resetCurrentPlan");
      }
    },

    updateCurrentCustomer(context, customerData) {
      context.commit("updateCurrentCustomer", customerData);
    },

    useZendeskPlugin(context, userData) {
      const zendesk_key = userData ? userData.merchant.pos_zendesk_chat_widget_key : "";

      if (!zendesk_key) {
        return;
      }

      Vue.use(Zendesk, {
        key: zendesk_key,
        disabled: false,
        hideOnLoad: false,
        settings: ZENDESK_WIDGET_SETTINGS,
      });
    },

    useHotjarPlugin(context, userData) {
      const enableHotjar = userData ? userData.merchant.pos_enable_hotjar : "";

      if (!enableHotjar) {
        return;
      }

      Vue.use(Hotjar, {
        id: HOTJAR_POS_ID,
        isProduction: enableHotjar,
        snippetVersion: 6,
      });

      if (Vue.prototype.$hj) {
        Vue.prototype.$hj("identify", userData.id, {
          email: userData.email,
          merchant_id: userData.merchant.id,
          merchant_name: userData.merchant.name,
        });
      }
    },

    loadTerminal(context) {
      context.commit("loadCurrentTerminal");
    },

    selectTerminal(context, terminal) {
      context.commit("setCurrentTerminal", terminal);
    },
  },

  getters: {
    isLoggedIn(state) {
      return state && state.user !== null && state.user.id !== null;
    },

    pnxPlans(state) {
      return state.activeFeePlans.filter((fp) => !fp.deferred_months && !fp.deferred_days);
    },

    deferredPlans(state) {
      return state.activeFeePlans.filter((fp) => !!fp.deferred_months || !!fp.deferred_days);
    },

    defaultInstallmentsCount(state, getters) {
      // Select 3 by default, otherwise the highest available (seems most likely that one will want to
      // pay in 4 rather than 2 installments if 3 isn't available; it's just a default anyway)
      return getters.pnxPlans
        .map((p) => p.installments_count)
        .reduce((prev, n) => (prev !== 3 && n > prev ? n : prev), 0);
    },

    /**
     * @param {{}}state
     * @returns {string | null}
     */
    getTerminalProvider(state) {
      return state.currentTerminalProvider;
    },
    /**
     * @param {{}}state
     * @returns {string | null}
     */
    getTerminalRef(state) {
      return state.currentTerminalRef;
    },
    /**
     * @param {{}}state
     * @returns {{} | null}
     */
    getTerminal(state) {
      return state.currentTerminal;
    },
  },
});

export default store;
