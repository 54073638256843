<template>
  <span :class="statusClass"></span>
</template>

<script>
/* TODO : Simplify arguments, no payments array needed anymore */
export default {
  name: "PaymentStatusIndicator",
  props: {
    payments: {
      type: Array,
      default() {
        return [];
      },
    },
    payment: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {};
  },

  computed: {
    statusClass() {
      const payments = this.payment ? [this.payment] : this.payments;

      if (payments.length === 0) {
        return "loading";
      }

      return payments.every((p) => ["paid", "in_progress"].includes(p.state))
        ? "all-paid"
        : "pending-payments";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/bulma.scss";

span {
  display: inline-block;
  width: 10px;
  height: 10px;

  border-radius: 5px;
  border: 1px solid $grey;
  background-color: $grey-light;
  box-shadow: 0 0 4px 0 $grey-light;

  &.loading {
    animation: pulse 2s infinite;
  }

  &.pending-payments {
    background-color: $yellow;
    box-shadow: 0 0 4px 0 $yellow;
    border-color: darken($yellow, 4%);
  }

  &.all-paid {
    background-color: $green;
    box-shadow: 0 0 4px 0 $green;
    border-color: darken($green, 4%);
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 transparentize($grey-light, 0.2);
    }
    70% {
      box-shadow: 0 0 0 5px rgba(169, 169, 169, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(169, 169, 169, 0);
    }
  }
}
</style>
