export default {
  computed: {
    /**
     * Returns the css class on the amount field
     * @returns {{input: boolean, "is-inline": boolean, "is-danger": *, "is-success": (*|boolean), "purchase-amount": boolean}}
     */
    purchaseAmountClasses() {
      return {
        "purchase-amount": true,
        input: true,
        "is-inline": true,
        "is-danger": this.validation.hasError("value.purchase_amount"),
        "is-success":
          this.validation.isTouched("value.purchase_amount") &&
          !this.validation.hasError("value.purchase_amount"),
      };
    },
  },
  methods: {
    /**
     * @param {string} value
     * @returns {string}: cleaned value
     */
    cleanPurchaseAmount(value) {
      value = value.replace(/[^0-9.,]/gi, "");
      [".", ","].forEach(function (item) {
        if (value.includes(item)) {
          value = value.slice(0, value.indexOf(item) + 3);
        }
      });
      return value;
    },

    /**
     * Update the purchase amount in the store
     * @param {string} value: The purchase amount as string number
     * @returns {Promise<any>}
     */
    updatePurchaseAmount(value) {
      value = this.cleanPurchaseAmount(value);
      const inVal = value;
      const roundVal = Math.round(Number(value.replace(",", ".")) * 100);
      return this.$store.dispatch("updateCurrentPayment", {
        purchase_amount_input: inVal,
        purchase_amount: roundVal,
      });
    },
  },
};
