<template>
  <button @click="clicked" class="button" :disabled="disabled || forceDisabled">
    <slot></slot>
    <slot name="label"></slot>
  </button>
</template>

<script>
export default {
  name: "DelayedButton",
  props: {
    disabled: Boolean,
    autoStart: Boolean,
    initialDelay: {
      type: Number,
      default: 30,
    },
    delayFactor: {
      type: Number,
      default: 1.5,
    },
  },

  data() {
    return {
      timeLeft: null,
      lastCountdown: this.initialDelay / this.delayFactor,
    };
  },

  mounted() {
    if (this.autoStart) {
      this.startCountdown();
    }
  },

  methods: {
    clicked() {
      this.startCountdown();
      this.$emit("click");
    },

    startCountdown() {
      this.timeLeft = Math.round(this.lastCountdown * 1.5);
      this.lastCountdown = this.timeLeft;

      this.countdownInterval = setInterval(() => {
        this.timeLeft -= 1;
        if (this.timeLeft === 0) {
          this.stopCountdown();
        }
      }, 1000);
    },

    stopCountdown() {
      this.timeLeft = null;
      clearInterval(this.countdownInterval);
    },
  },

  computed: {
    forceDisabled() {
      return this.timeLeft !== null;
    },
  },
};
</script>
