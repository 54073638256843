<template>
  <section class="purchase">
    <translate>Le client souhaite payer</translate>&nbsp;
    <FeePlanTypeSwitch
      class="is-inline-block"
      v-if="showFeePlanTypeSwitch"
      :value="feePlanType"
      @change="updateFeePlanType($event)"
    />
    <div v-if="!!feePlanType || !showFeePlanTypeSwitch" class="is-inline-block">
      <span v-if="showFeePlanTypeSwitch"><translate>un montant de</translate> </span>
      <input
        ref="purchaseAmount"
        :value="value.purchase_amount_input"
        @input="updatePurchaseAmount($event.target.value)"
        type="text"
        :class="purchaseAmountClasses"
        :disabled="disabled"
      />
      €
      <span v-if="isPnx || useTerminal">
        <!-- TODO fix string  -->
        <translate translate-comment="<en >(n|plusieurs) fois.">en </translate>
        <span v-if="useTerminal"
          ><translate translate-comment="en (n|<plusieurs>) fois.">plusieurs</translate></span
        >
        <div v-else class="select is-inline-block">
          <select
            :value="value.installments_count"
            @input="updateInstallmentsCount($event.target.value)"
            :class="installmentsCountClasses"
            :disabled="disabled"
          >
            <option v-for="n in installmentsCounts" :key="n" :value="n">
              {{ n }}
            </option>
          </select>
        </div>
        <translate
          v-if="useTerminal || value.installments_count > 1"
          translate-comment="en (n|plusieurs)< fois.>"
          translate-context="plural"
        >
          fois.</translate
        >
        <translate v-else translate-comment="en (n|plusieurs)< fois.>" translate-context="singular">
          fois.</translate
        >
      </span>
      <span v-else-if="isDeferred">
        <translate>dans</translate>
        <div class="select is-inline-block">
          <select
            :value="value.deferred_option_input"
            @input="updateDeferredOption($event.target.value)"
            :class="deferredOptionClasses"
            :disabled="disabled"
          >
            <option v-for="opt in deferredOptions" :key="opt.value" :value="String(opt.value)">
              {{ String(opt.label) }}
            </option>
          </select>
        </div>
      </span>
    </div>
    <p
      class="help is-danger"
      v-show="
        feePlanType &&
        validation.isTouched('value.purchase_amount') &&
        validation.hasError('value.purchase_amount')
      "
    >
      {{ validation.firstError("value.purchase_amount") }}
    </p>

    <div v-if="useTerminal && displayPaymentPlans" class="payment-plan">
      <label class="label" v-translate>Simulations d'échéances</label>
      <MiniPaymentPlan v-for="plan in eligiblePlans" :plan="plan" :key="plan.installments_count" />
    </div>
    <div v-else-if="paymentPlan && paymentPlan.eligible" class="payment-plan">
      <label class="label" v-translate>Votre échéancier</label>
      <ul class="installments">
        <li
          v-for="inst in paymentPlan.payment_plan"
          :key="inst.due_date + inst.purchase_amount + inst.customer_fee"
          class="installment"
        >
          {{ (inst.purchase_amount + inst.customer_fee + inst.customer_interest) | euros }}
          <small v-if="inst.customer_fee + inst.customer_interest > 0">
            (<translate>dont</translate>
            <ul class="fee-details">
              <li v-if="inst.customer_fee > 0">
                &nbsp;<translate
                  :translate-params="{
                    customer_fee: $options.filters.euros(inst.customer_fee),
                  }"
                  >%{ customer_fee } de frais</translate
                >
              </li>
              <li v-if="inst.customer_interest > 0">
                &nbsp;<translate
                  :translate-params="{
                    customer_interest: $options.filters.euros(inst.customer_interest),
                  }"
                  >%{ customer_interest } d'intérêts</translate
                >
              </li>
            </ul>
            )
          </small>
          <translate :translate-params="{ due_date: $options.filters.moment(inst.due_date) }"
            >le %{due_date}</translate
          >
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import MiniPaymentPlan from "./MiniPaymentPlan";
import FeePlanTypeSwitch from "./FeePlanTypeSwitch";
import SimpleVueValidation from "simple-vue-validator";
import PaymentMixin from "../../mixins/paymentMixin";

const Validator = SimpleVueValidation.Validator.create();

export default {
  name: "PurchaseWithInstallments",
  mixins: [PaymentMixin],
  components: { MiniPaymentPlan, FeePlanTypeSwitch },
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    "$store.state.user.locale": async function () {
      if (Array.isArray(this.validation.errors)) {
        const fieldsToValidate = this.validation.errors.map((error) => error.field);
        this.$validate(fieldsToValidate);
      }
    },
  },
  computed: {
    eligiblePlans() {
      let plans = this.$store.state.allPlans.filter((p) => p.eligible);

      if (this.useTerminal) {
        plans = plans.filter((p) => p.installments_count > 1);
      }

      return plans;
    },
    installmentsCounts() {
      return new Set(this.pnxPlans.map((fp) => fp.installments_count));
    },
    paymentPlan() {
      return this.$store.state.currentPlan;
    },

    displayPaymentPlans() {
      const allPlans = this.$store.state.allPlans;
      const payment = this.$store.state.currentPayment;
      return payment.purchase_amount > 0 && allPlans.length > 0 && allPlans.some((p) => p.eligible);
    },

    installmentsCountClasses() {
      return {
        input: true,
        "is-danger":
          this.validation.isTouched("value.installments_count") &&
          this.validation.hasError("value.installments_count"),
      };
    },

    deferredOptionClasses() {
      return {
        input: true,
        "is-danger":
          this.validation.isTouched("value.deferred_option_input") &&
          this.validation.hasError("value.deferred_option_input"),
      };
    },
    useTerminal() {
      return this.$store.state.user && this.$store.state.user.use_terminal;
    },

    feePlanType() {
      return this.value ? this.value.fee_plan_type : null;
    },

    isPnx() {
      return this.feePlanType === "pnx";
    },

    isDeferred() {
      return this.feePlanType === "deferred";
    },

    pnxPlans() {
      return this.$store.getters.pnxPlans;
    },

    deferredPlans() {
      return this.$store.getters.deferredPlans;
    },

    showFeePlanTypeSwitch() {
      return this.pnxPlans.length !== 0 && this.deferredPlans.length !== 0 && !this.useTerminal;
    },
    deferredOptions() {
      return this.deferredPlans.map((fp) => {
        if (fp.deferred_days) {
          let translated = this.$ngettext("%{ n } jour", "%{ n } jours", fp.deferred_days);
          return {
            label: this.$gettextInterpolate(translated, { n: fp.deferred_days }),
            value: `${fp.deferred_days} d`,
          };
        } else if (fp.deferred_months) {
          let translated = this.$ngettext("%{ n } mois", "%{ n } mois", fp.deferred_months);
          return {
            label: this.$gettextInterpolate(translated, { n: fp.deferred_months }),
            value: `${fp.deferred_months} m`,
          };
        }
      });
    },
    defaultDeferredOption() {
      return this.deferredOptions[0].value;
    },
  },
  methods: {
    async updateFeePlanType(value) {
      await this.updateModel("fee_plan_type", value);

      if (value === "deferred") {
        await this.updateDeferredOption(this.defaultDeferredOption);
      } else {
        // We need to "atomically" change all properties at once, otherwise we'd temporarily be in an
        // inconsistent state where currentPayment would effectively be a deferred pnx payment, and
        // that doesn't work so well.
        await this.$store.dispatch("updateCurrentPayment", {
          installments_count: this.defaultInstallmentsCount,
          deferred_option_input: null,
          deferred_days: 0,
          deferred_months: 0,
        });
      }
    },

    /**
     * @param value
     * @returns {Promise<*>}
     */
    updateInstallmentsCount(value) {
      return this.updateModel("installments_count", Number(value));
    },

    /**
     * @param attr
     * @param value
     * @returns {Promise<any>}
     */
    updateModel(attr, value) {
      // The full object must be emitted for each update
      return this.$store.dispatch("updateCurrentPayment", { [attr]: value });
    },

    updateDeferredOption(value) {
      let deferred_days = 0;
      let deferred_months = 0;
      if (value) {
        const data = value.split(" ");
        if (data[1] === "d") {
          deferred_days = Number(data[0]);
        } else if (data[1] === "m") {
          deferred_months = Number(data[0]);
        }
      }
      return this.$store.dispatch("updateCurrentPayment", {
        installments_count: 1,
        deferred_option_input: value,
        deferred_days: deferred_days,
        deferred_months: deferred_months,
      });
    },
  },

  validators: {
    "value.purchase_amount"(value) {
      if (this.validation.resetting) return;

      const merchant = this.$store.state.user.merchant;
      let minAmount;
      let maxAmount;

      // Do not use this.paymentPlan or this.$store.state.allPlans as they are eligibility data,
      // not fee plans, so they don't have min and max purchase amount fields.
      const feePlan = this.$store.state.activeFeePlans.find(
        (p) =>
          p.installments_count === this.value.installments_count &&
          Number(p.deferred_days || null) === Number(this.value.deferred_days || null) &&
          Number(p.deferred_months || null) === Number(this.value.deferred_months || null)
      );

      if (this.useTerminal) {
        // When using a terminal, we don't validate the amount against a specific fee plan, but
        // against the global min & max values; the terminal itself will display the eligible plans
        // for the chosen amount.
        ({ minAmount, maxAmount } = this.$store.state.activeFeePlans
          .filter((fp) => fp.installments_count > 1)
          .reduce(
            (memo, plan) => ({
              minAmount: Math.min(memo.minAmount, plan.min_purchase_amount),
              maxAmount: Math.max(memo.maxAmount, plan.max_purchase_amount),
            }),
            { minAmount: Number.MAX_SAFE_INTEGER, maxAmount: 0 }
          ));
      } else {
        minAmount = feePlan.min_purchase_amount;
        maxAmount = feePlan.max_purchase_amount;
      }
      minAmount = Math.max(minAmount, merchant.minimum_purchase_amount);
      maxAmount = Math.min(maxAmount, merchant.maximum_purchase_amount);

      if (this.value.installments_count === 1 && !this.value.deferred_days && !this.value.deferred_months) {
        minAmount = 50;
        maxAmount = feePlan.max_purchase_amount;
      }

      // Use the value in euros so that error message displays euros as well
      return Validator.value(Number(value))
        .required()
        .between(
          minAmount,
          maxAmount,
          this.$gettextInterpolate(
            this.$gettext("Le montant doit être compris entre %{minAmount} € et %{maxAmount} €."),
            { minAmount: minAmount / 100, maxAmount: maxAmount / 100 }
          )
        );
    },

    "value.installments_count"(value) {
      if (this.validation.resetting) return;

      if (this.useTerminal) {
        return Validator.value(true);
      }

      const validation = Validator.value(Number(value));
      if (Array.from(this.installmentsCounts).length === 0) return validation;

      const min_count = Math.min(...this.installmentsCounts);
      const max_count = Math.max(...this.installmentsCounts);
      return validation.required().between(min_count, max_count);
    },

    "value.custom_data"() {
      if (this.validation.resetting) return;
      if (!this.$refs.fields) {
        return Validator.value(true);
      }
      const check = this.$refs.fields
        .map(function (field) {
          return field.validation.errors.length === 0 && (!field.required || !!field.value.trim());
        })
        .reduce(function (a, b) {
          return a && b;
        }, true);

      return Validator.value(check || "").required();
    },

    "value.deferred_option_input"(value) {
      if (this.validation.resetting) return;

      const validation = Validator.value(value);
      return this.isDeferred
        ? validation.in(this.deferredOptions.map((opt) => opt.value)).required()
        : validation;
    },
  },
};
</script>

<style scoped>
.is-inline {
  vertical-align: baseline;
}

.purchase-amount {
  width: 100px !important;
}

section.purchase {
  margin-bottom: 2rem;
}

.payment-plan {
  margin-top: 1rem;
}

.mini-payment-plan {
  margin: 10px 0;
}

.fee-details {
  display: inline;
  list-style: none;
  margin: 0;
}

.fee-details li {
  margin: 0;
  display: inline;
}

.fee-details li:before {
  content: " / ";
}

.fee-details li:first-child:before {
  content: "";
}
</style>
