<template>
  <li class="payment">
    <div class="payment-row">
      <div class="status"><PaymentStatusIndicator :payment="payment" /></div>

      <div class="info">
        <div v-if="orderReference" class="order-reference is-size-7">{{ orderReference }}</div>
        <div class="customer-name has-text-weight-bold">{{ customerName }}</div>
        <div class="relative-time is-size-7">{{ relativeTime }}</div>
      </div>
      <div class="purchase-amount has-text-weight-bold">{{ purchaseAmount }} €</div>
      <div>
        <div class="actions">
          <BarCodeButton
            :purchaseAmount="purchaseAmount"
            :value="paymentId"
            size="is-small"
            :disabled="!isPaymentDone"
          ></BarCodeButton>
          <a
            class="button copy is-small"
            v-if="isPaymentDone"
            title="Copier"
            :href="sanitizeUrl(adminURL)"
            :target="adminURL !== `#` && `_blank`"
          >
            <i class="fas fa-external-link-alt"></i>
          </a>
          <span class="button copy is-small disabled" v-else title="Copier" href="#">
            <i class="fas fa-external-link-alt"></i>
          </span>
        </div>
      </div>
    </div>
    <AlertPaymentNotDone v-if="!isPaymentDone" />
  </li>
</template>

<script>
import * as moment from "moment";
import { DASHBOARD_URL } from "../../lib/config";
import PaymentStatusIndicator from "../PaymentStatusIndicator";
import BarCodeButton from "../ui/BarCodeButton";
import AlertPaymentNotDone from "@/components/payment/AlertPaymentNotDone";
const sanitizeUrl = require("@braintree/sanitize-url").sanitizeUrl;

export default {
  name: "Payment",
  props: {
    payment: {
      required: true,
      type: Object,
    },
    now: {
      required: true,
      type: Object,
    },
  },

  components: { BarCodeButton, PaymentStatusIndicator, AlertPaymentNotDone },

  data() {
    return {
      sanitizeUrl: sanitizeUrl,
    };
  },
  computed: {
    adminURL() {
      return this.payment.state === "not_started" ? `#` : `${DASHBOARD_URL}/payments/${this.payment.id}`;
    },

    customerName() {
      return `${this.payment.customer.first_name} ${this.payment.customer.last_name}`;
    },

    orderReference() {
      return this.payment.orders.length ? this.payment.orders[0].merchant_reference : null;
    },

    paymentId() {
      return this.payment.id.replace("payment_", "");
    },

    relativeTime() {
      // The reference time is refreshed every minute, so the payment's creation date *might* be in the future of
      // that reference. To work around this, just use a generic label until the reference time is updated.
      if (moment.unix(this.payment.created).utc().isAfter(this.now)) {
        return "il y a quelques secondes";
      } else {
        return moment.unix(this.payment.created).utc().from(this.now);
      }
    },

    purchaseAmount() {
      return this.payment.purchase_amount / 100;
    },
    isPaymentDone() {
      const payments = this.payment ? [this.payment] : this.payments;

      if (payments.length === 0) {
        return false;
      }

      return payments.every((p) => ["paid", "in_progress"].includes(p.state));
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/bulma";

.payment {
  border: 1px solid $grey-lighter;
  border-radius: 3px;

  margin: 10px 0;
  padding: 10px 15px;

  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #fafafa;
    box-shadow: 0 1px 2px 0 $grey-lighter;
  }

  display: flex;
  flex-direction: column;

  color: inherit;

  .status {
    display: flex;
    align-items: center;

    margin: 10px 20px 10px 10px;
  }

  .info {
    flex-grow: 1;
  }

  .purchase-amount {
    display: flex;
    align-items: center;

    font-size: 1.2em;
  }

  .actions {
    margin-left: 10px;
  }

  .payment-row {
    display: flex;
    justify-content: space-between;
  }
  .disabled {
    color: #e6e4e4;
    border-color: #e6e4e4;
    cursor: not-allowed;
  }
}
</style>
