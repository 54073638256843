<template>
  <Dropdown class="is-right" :button-label="user.merchant.name">
    <a class="dropdown-item has-text-danger has-text-weight-bold" @click="logout">
      <translate>Déconnexion</translate>
    </a>
    <hr class="dropdown-divider" />
    <a class="dropdown-item" v-for="m in user.merchants" :key="m.value" @click="switchAccount(m.value)">
      {{ m.name }}
    </a>
  </Dropdown>
</template>

<script>
import Dropdown from "@/components/Dropdown";
import { mapActions } from "vuex";

export default {
  name: "AccountDropdown",
  components: { Dropdown },
  props: ["user"],

  methods: {
    async switchAccount(value) {
      await this.$store.dispatch("switchAccount", value);
    },

    ...mapActions(["logout"]),
  },
};
</script>

<style lang="scss" scoped></style>
