<template>
  <p class="control">
    <!-- todo set size again -->
    <a class="button copy" :class="[{ disabled: disabled }, size]" @click="openModal" title="Copier">
      <i class="fas fa-barcode"></i>
    </a>
    <BulmaModal v-model="dialog">
      <VueBarcode :value="paymentReference" format="CODE128"> Show this if the rendering fails. </VueBarcode>
      <p v-if="purchaseAmount"><translate>Montant</translate> : {{ purchaseAmount }} €</p>
    </BulmaModal>
  </p>
</template>

<script>
import BulmaModal from "./BulmaModal";
import VueBarcode from "vue-barcode";
export default {
  name: "BarCodeButton",
  props: {
    value: {
      type: String,
      default: "",
    },
    purchaseAmount: {
      type: Number,
    },
    size: {
      type: String,
      default: `is-medium`,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    paymentReference() {
      return this.value.split(`/`).pop();
    },
  },
  components: { BulmaModal, VueBarcode },
  methods: {
    openModal() {
      // We don't want to open the modal if the link is disabled.
      if (!this.disabled) {
        this.dialog = true;
      }
    },
  },
};
</script>

<style scoped>
.disabled {
  color: #e6e4e4 !important; /*bulma is overriding button:hover*/
  border-color: #e6e4e4 !important; /*bulma is overriding button:hover*/
  cursor: not-allowed;
}
</style>
