export class ExpiredSessionError extends Error {}

export class InternalServerError extends Error {}

export class ValidationError extends Error {
  constructor(errors) {
    super();
    this.errors = errors;
  }

  errorsList() {
    return this.errors.map((e) => e.message);
  }
}
