<template>
  <div class="field">
    <label :for="name" class="label is-inline-block"> {{ label }}{{ required ? "*" : "" }} </label>
    <span v-if="desc">&nbsp;&ndash;&nbsp;{{ desc }}</span>
    <input
      v-model="value"
      @input="updateModel()"
      type="text"
      :class="inputClass()"
      :name="name"
      :id="name"
      :disabled="disabled"
      :required="required"
    />
    <p class="help is-danger" v-show="validation.hasError('value')">
      {{ validation.firstError("value") }}
    </p>
  </div>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import { translate } from "vue-gettext";

const { gettext: $gettext } = translate;

const Validator = SimpleVueValidation.Validator.create({
  templates: { between: $gettext("Le montant doit être compris entre {0} € et {1} €.") },
});

export default {
  name: "PaymentCustomField",
  props: ["name", "label", "required", "disabled", "desc", "regex"],

  data: function () {
    return {
      value: "",
    };
  },

  watch: {
    "$store.state.user.locale": async function () {
      if (Array.isArray(this.validation.errors)) {
        const fieldsToValidate = this.validation.errors.map((error) => error.field);
        this.$validate(fieldsToValidate);
      }
    },
  },
  methods: {
    updateModel() {
      this.$emit("input", this.value);
    },

    validate: function () {
      return this.$validate().then(
        function (success) {
          if (success) {
            return { value: this.value };
          }
        }.bind(this)
      );
    },

    inputClass() {
      return {
        input: true,
        "is-danger": this.validation.hasError("value"),
        "is-success": this.validation.isTouched("value") && !this.validation.hasError("value") && this.value,
      };
    },
  },

  validators: {
    value(value) {
      let validator = Validator.value(value);
      if (this.required) {
        validator = validator.required();
      }
      if (this.regex) {
        validator = validator.regex(this.regex);
      }
      return validator;
    },
  },
};
</script>

<style scoped>
.is-inline {
  vertical-align: baseline;
}

.purchase-amount {
  width: 100px !important;
}

section.purchase {
  margin-bottom: 2rem;
}
</style>
