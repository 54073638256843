<template>
  <div class="column-display">
    <div class="input-with-copy field has-addons">
      <p class="control is-expanded">
        <input
          ref="input"
          class="input is-medium"
          type="text"
          :value="value"
          readonly
          :class="{
            highlighted: highlighted,
          }"
        />
      </p>
      <CopyButton v-model="value" :disabled="disabled"></CopyButton>
      <BarCodeButton
        v-if="!hideBarCode"
        :purchaseAmount="purchaseAmount"
        v-model="value"
        :disabled="disabled"
      ></BarCodeButton>
      <br />
    </div>
  </div>
</template>

<script>
import CopyButton from "./ui/CopyButton";
import BarCodeButton from "./ui/BarCodeButton";
export default {
  name: "InputWithCopy",
  components: { BarCodeButton, CopyButton },
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hideBarCode: {
      type: Boolean,
      default: false,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    lastPayment() {
      return [...this.$store.state.payments].sort((p1, p2) => p2.created - p1.created)[0];
    },
    purchaseAmount() {
      return this.lastPayment.purchase_amount / 100;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/bulma.scss";

.column-display {
  display: flex;
  flex-direction: column;
}
.highlighted {
  background-color: lighten($yellow, 30%);
}
</style>
