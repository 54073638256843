<template functional>
  <section class="overlay hero is-fullheight is-light">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-three-fifths-desktop">
            <div class="content has-text-centered">
              <slot><translate>Chargement en cours ...</translate></slot>
            </div>
            <progress class="progress is-xsmall is-primary" max="100"></progress>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "LoadingOverlay",
  functional: true,
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.is-xsmall {
  height: 0.2rem;
}
</style>
