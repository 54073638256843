var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar is-fixed-bottom is-spaced pl-2 pr-2 py-3"},[_c('div',{staticClass:"tag is-dark vertical_center"},[_vm._v("v"+_vm._s(_vm.VUE_APP_VERSION))]),_c('div',{staticClass:"columns is-mobile is-multiline"},[(!_vm.hasPayment)?_c('div',{staticClass:"column is-hidden-mobile"}):_vm._e(),(!_vm.useTerminal)?[(this.$store.state.user && this.$store.state.user.merchant.has_pos_pay_by_sms)?_c('div',{class:_vm.smsButtonWrapperClasses},[_c('DelayedButton',{ref:"smsButton",class:_vm.smsButtonClasses,attrs:{"disabled":_vm.disableButton('sms')},on:{"click":_vm.submitSMS},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.hasPayment && _vm.sentSms)?[_c('translate',[_vm._v("Renvoyer SMS")])]:[_c('translate',[_vm._v("Payer sur mobile")])]]},proxy:true}],null,false,3369718686)},[_c('div',{staticClass:"icon"},[_c('i',{staticClass:"far fa-comment-dots"})])])],1):_vm._e(),_c('div',{class:_vm.linkInputClasses},[(_vm.hasPayment)?_c('InputWithCopy',{attrs:{"value":_vm.payment.url,"hideBarCode":true}}):_c('button',{class:_vm.linkButtonClasses,attrs:{"disabled":_vm.disableButton('link')},on:{"click":function($event){return _vm.$emit('submit', 'link')}}},[_c('translate',[_vm._v("Créer un lien")])],1)],1)]:_vm._e(),(!_vm.hasPayment && this.$store.state.user && this.$store.state.user.merchant.has_pos_pay_on_device)?[(_vm.useTerminal && _vm.hasSeveralTerminals)?_c('div',{staticClass:"column is-narrow is-half-mobile is-offset-one-quarter-mobile has-text-centered"},[_c('TerminalDropdown')],1):_vm._e(),_c('div',{class:[
          'column',
          'is-half-mobile',
          'is-3-tablet',
          'is-narrow', // required for the spacer column above to work
          {
            'is-offset-one-quarter-mobile': _vm.useTerminal,
          } ]},[_c('button',{class:_vm.payButtonClasses,attrs:{"disabled":_vm.disableButton(_vm.mainButtonTarget)},on:{"click":function($event){return _vm.$emit('submit', _vm.mainButtonTarget)}}},[_c('translate',[_vm._v("Payer")])],1)])]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }