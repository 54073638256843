<template>
  <nav class="navbar is-fixed-bottom is-spaced pl-2 pr-2 py-3">
    <div class="tag is-dark vertical_center">v{{ VUE_APP_VERSION }}</div>
    <div class="columns is-mobile is-multiline">
      <!-- Use a spacer column that will automatically push the buttons to the right side -->
      <div v-if="!hasPayment" class="column is-hidden-mobile"></div>
      <template v-if="!useTerminal">
        <div
          :class="smsButtonWrapperClasses"
          v-if="this.$store.state.user && this.$store.state.user.merchant.has_pos_pay_by_sms"
        >
          <DelayedButton
            ref="smsButton"
            @click="submitSMS"
            :class="smsButtonClasses"
            :disabled="disableButton('sms')"
          >
            <div class="icon">
              <i class="far fa-comment-dots"></i>
            </div>

            <template #label>
              <template v-if="hasPayment && sentSms">
                <translate>Renvoyer SMS</translate>
              </template>
              <template v-else><translate>Payer sur mobile</translate></template>
            </template>
          </DelayedButton>
        </div>

        <div :class="linkInputClasses">
          <InputWithCopy v-if="hasPayment" :value="payment.url" :hideBarCode="true" />
          <button
            v-else
            :class="linkButtonClasses"
            :disabled="disableButton('link')"
            @click="$emit('submit', 'link')"
          >
            <translate>Créer un lien</translate>
          </button>
        </div>
      </template>
      <template
        v-if="!hasPayment && this.$store.state.user && this.$store.state.user.merchant.has_pos_pay_on_device"
      >
        <div
          v-if="useTerminal && hasSeveralTerminals"
          class="column is-narrow is-half-mobile is-offset-one-quarter-mobile has-text-centered"
        >
          <TerminalDropdown />
        </div>
        <div
          :class="[
            'column',
            'is-half-mobile',
            'is-3-tablet',
            'is-narrow', // required for the spacer column above to work
            {
              'is-offset-one-quarter-mobile': useTerminal,
            },
          ]"
        >
          <button
            @click="$emit('submit', mainButtonTarget)"
            :class="payButtonClasses"
            :disabled="disableButton(mainButtonTarget)"
          >
            <translate>Payer</translate>
          </button>
        </div>
      </template>
    </div>
  </nav>
</template>

<script>
import DelayedButton from "../DelayedButton";
import InputWithCopy from "../InputWithCopy";
import TerminalDropdown from "@/components/TerminalsDropdown";
export default {
  name: "PaymentBar",
  props: ["payment", "loadingTarget", "canCreatePayment"],
  components: { TerminalDropdown, DelayedButton, InputWithCopy },

  data() {
    return {
      sentSms: false,
      VUE_APP_VERSION: process.env.VUE_APP_VERSION,
    };
  },

  methods: {
    disableButton(target) {
      return !this.canCreatePayment || (this.loadingTarget && this.loadingTarget !== target);
    },

    reset() {
      this.$refs.smsButton && this.$refs.smsButton.stopCountdown();
      this.sentSms = false;
    },

    submitSMS() {
      this.$emit("submit", "sms");
      this.sentSms = true;
    },
  },

  computed: {
    useTerminal() {
      return this.$store.state.user && this.$store.state.user.use_terminal;
    },

    hasSeveralTerminals() {
      return (
        this.$store.state.user &&
        this.$store.state.user.terminals &&
        this.$store.state.user.terminals.length > 1
      );
    },

    mainButtonTarget() {
      return this.useTerminal ? "terminal" : "device";
    },

    hasPayment() {
      return !!this.payment.id;
    },

    smsButtonWrapperClasses() {
      return [
        "column",
        "is-full-mobile",
        this.hasPayment ? "is-half-tablet" : "is-4-tablet-only is-3-desktop",
      ];
    },

    smsButtonClasses() {
      return [
        "is-success",
        "is-medium",
        "is-fullwidth",
        "has-icons-left",
        { "is-loading": this.loadingTarget === "sms" },
      ];
    },

    linkInputClasses() {
      if (this.hasPayment) {
        return ["column", "is-full-mobile", "is-half-tablet"];
      } else if (this.$store.state.user && !this.$store.state.user.merchant.has_pos_pay_by_link) {
        return ["is-hidden"];
      } else {
        return ["column", "is-half-mobile", "is-3-tablet"];
      }
    },

    linkButtonClasses() {
      return ["button", "is-medium", "is-fullwidth", { "is-loading": this.loadingTarget === "link" }];
    },

    payButtonClasses() {
      return [
        "button",
        "is-primary",
        "is-medium",
        "is-fullwidth",
        "has-icons-right",
        { "is-loading": this.loadingTarget === this.mainButtonTarget },
      ];
    },
  },
};
</script>

<style scoped>
button.has-icons-left > .icon:last-child {
  margin-right: 5px;
}
button.has-icons-right > .icon:first-child {
  margin-left: 5px;
}

.vertical_center {
  margin-top: auto;
  margin-bottom: auto;
  align-self: start;
}

.is-multiline {
  flex-grow: 1;
  display: flex;
}
</style>
