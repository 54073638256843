import Vue from "vue";
import App from "./App";
import router from "./router";
import { DEFAULT_LOCALE, SENTRY_JS_DSN, RUNNING_ENV } from "./lib/config";
import SimpleVueValidation from "simple-vue-validator";

import GetTextPlugin, { translate } from "vue-gettext";
const { gettext: $gettext } = translate;
import translations from "./translations/messages.json";
import * as moment from "moment";
import "moment/locale/fr";

import * as Sentry from "@sentry/vue";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

if (SENTRY_JS_DSN !== "") {
  try {
    Sentry.init({
      Vue: Vue,
      dsn: SENTRY_JS_DSN,
      environment: RUNNING_ENV,
    });
  } catch (e) {
    /* eslint-disable no-console */
    if (console && console.error) {
      /* eslint-disable no-console */
      console.error(e);
    }
  }
}

import "./assets/scss/bulma.scss";

function getDefaultLocale() {
  if (Vue.$cookies.isKey("alma_locale")) {
    return Vue.$cookies.get("alma_locale");
  } else {
    switch (navigator.language) {
      case "en-US":
      case "en-UK":
        return "en";
      case "it-IT":
        return "it";
      case "fr-FR":
        return "fr";
      case "es-ES":
        return "es";
      case "de-DE":
        return "de";
      case "nl-NL":
        return "nl";
      case "nl-BE":
        return "nl";
      default:
        return DEFAULT_LOCALE;
    }
  }
}

moment.locale(getDefaultLocale());
Vue.use(GetTextPlugin, {
  availableLanguages: {
    de: "Deutsch",
    en: "English",
    es: "Español",
    fr: "Français",
    it: "Italiano",
    nl: "Nederlands",
  },
  defaultLanguage: getDefaultLocale(),
  translations: translations,
  silent: true,
});

export const extendValidationTemplate = () => {
  SimpleVueValidation.extendTemplates({
    error: $gettext("Erreur."),
    required: $gettext("Requis."),
    float: $gettext("Doit être un nombre."),
    integer: $gettext("Doit être un entier."),
    number: $gettext("Doit être un nombre."),
    lessThan: $gettext("Doit être inférieur à {0}."),
    lessThanOrEqualTo: $gettext("Doit être inférieur ou égal à {0}."),
    greaterThan: $gettext("Doit être supérieur à {0}."),
    greaterThanOrEqualTo: $gettext("Doit être supérieur ou égal à {0}."),
    between: $gettext("Doit être compris entre {0} et {1}."),
    size: $gettext("Doit avoir exactement {0} éléments."),
    length: $gettext("Doit avoir exactement {0} caractères."),
    minLength: $gettext("Doit avoir au moins {0} caractères."),
    maxLength: $gettext("Doit avoir au plus {0} caractères."),
    lengthBetween: $gettext("Doit avoir entre {0} et {1} caractères."),
    in: $gettext("Doit être {0}."),
    notIn: $gettext("Ne doit pas être {0}."),
    match: $gettext("Ne correspond pas."),
    regex: $gettext("Format invalide."),
    digit: $gettext("Doit être un chiffre."),
    email: $gettext("Email invalide."),
    url: $gettext("URL invalide."),
    optionCombiner: function (options) {
      if (options.length > 2) {
        options = [options.slice(0, options.length - 1).join(", "), options[options.length - 1]];
      }
      return options.join(" ou ");
    },
  });
};
extendValidationTemplate();
Vue.use(SimpleVueValidation);

Vue.config.productionTip = false;

Vue.filter("moment", function momentFilter(date) {
  return moment.unix(date).format("D MMMM YYYY");
});

Vue.filter("euros", function eurosFilter(cents) {
  return Number((cents / 100).toFixed(2)).toLocaleString() + " €";
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
