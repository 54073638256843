<template>
  <div class="fee-plan-type-switch">
    <div class="control buttons has-addons">
      <label :class="buttonClasses(key)" v-for="(label, key) in options" v-bind:key="key">
        <input
          type="radio"
          name="fee_plan_type"
          :value="key"
          @change="$emit('change', $event.target.value)"
        />
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: "FeePlanTypeSwitch",
  props: ["value"],
  computed: {
    options: function () {
      return {
        pnx: this.$gettext("en plusieurs fois"),
        deferred: this.$gettext("plus tard"),
      };
    },
  },

  methods: {
    buttonClasses(key) {
      const selected = key === this.value;
      return { button: true, "is-active": selected, "is-primary": selected };
    },
  },
};
</script>

<style scoped>
.button input[type="radio"] {
  display: none;
}

.fee-plan-type-switch {
  margin: 0.2rem auto 1rem;
}

section.purchase {
  margin-bottom: 2rem;
}
</style>
