<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title" v-translate>Informations client</p>
      <p class="card-header-icon">
        <span class="icon">
          <i class="fas fa-user"></i>
        </span>
      </p>
    </header>
    <div class="card-content">
      <div class="fields content">
        <!-- First & last names -->
        <div class="columns">
          <div class="column">
            <div class="field">
              <label for="first-name" class="label"><translate>Prénom</translate>*</label>
              <div class="control">
                <input
                  v-model="value.first_name"
                  @input="updateModel('first_name', $event.target.value)"
                  type="text"
                  :class="inputClass('first_name')"
                  id="first-name"
                  :placeholder="this.$gettext('Alice')"
                  :disabled="disabled"
                  ref="firstName"
                />
              </div>
              <p class="help is-danger" v-show="validation.hasError('value.first_name')">
                {{ validation.firstError("value.first_name") }}
              </p>
            </div>
          </div>

          <div class="column">
            <div class="field">
              <label for="last-name" class="label"><translate>Nom</translate>*</label>
              <div class="control">
                <input
                  v-model="value.last_name"
                  @input="updateModel('last_name', $event.target.value)"
                  type="text"
                  :class="inputClass('last_name')"
                  id="last-name"
                  :placeholder="this.$gettext('Dubois')"
                  :disabled="disabled"
                />
              </div>
              <p class="help is-danger" v-show="validation.hasError('value.last_name')">
                {{ validation.firstError("value.last_name") }}
              </p>
            </div>
          </div>
        </div>

        <!-- Email address -->
        <div class="columns">
          <div class="column is-half">
            <div class="field">
              <label for="email" class="label"><translate>Email</translate>*</label>
              <div class="control">
                <input
                  v-model="value.email"
                  @input="updateModel('email', $event.target.value)"
                  type="email"
                  :class="inputClass('email')"
                  id="email"
                  :placeholder="this.$gettext('alice.dubois@example.com')"
                  :disabled="disabled"
                />
              </div>
              <p class="help is-danger" v-show="validation.hasError('value.email')">
                {{ validation.firstError("value.email") }}
              </p>
            </div>
          </div>
          <div class="column is-half">
            <div class="field">
              <label for="phone" class="label"><translate>Téléphone portable</translate>*</label>
              <VuePhoneNumberInput
                v-model="phone"
                id="phone"
                ref="phoneInput"
                @update="updatePhone($event)"
                :default-country-code="merchantCountry"
                :placeholder="this.$gettext('0607080900')"
                :disabled="disabled"
                :translations="translations"
                color="#dbdbdb"
                valid-color="#48c774"
                error-color="#f14668"
                :error="validation.hasError('value.phone')"
              />
              <p class="help is-danger" v-show="validation.hasError('value.phone')">
                {{ validation.firstError("value.phone") }}
              </p>
            </div>
          </div>
        </div>

        <!-- Address -->
        <div class="field">
          <label for="address" class="label"><translate>Adresse</translate>*</label>
          <div class="control">
            <Places
              v-model="value.line1"
              :class="inputClass('line1')"
              id="address"
              :placeholder="this.$gettext('Numéro et nom de la voie')"
              :options="{
                type: 'address',
                useDeviceLocation: true,
                templates: {
                  value: (data) => data.name,
                },
                autocompleteOptions: {
                  templates: {
                    header: headerTemplate,
                  },
                },
              }"
              :disabled="disabled"
              @change="updateAddress"
              ref="autocomplete"
            />
          </div>
          <p class="help is-danger" v-show="validation.hasError('value.line1')">
            {{ validation.firstError("value.line1") }}
          </p>
        </div>

        <!-- Address line 2 -->
        <div class="field">
          <label for="address2" class="label" v-translate>Complément d'adresse</label>
          <div class="control">
            <input
              v-model="value.line2"
              @input="updateModel('line2', $event.target.value)"
              type="text"
              class="input"
              id="address2"
              :placeholder="this.$gettext('Boîte postale, étage, ...')"
              :disabled="disabled"
            />
          </div>
        </div>

        <!-- Postal code & city -->
        <div class="columns is-multiline">
          <div class="column" :class="columnClass()">
            <div class="field">
              <label for="postal-code" class="label"><translate>Code postal</translate>*</label>
              <div class="control">
                <input
                  v-model="value.postal_code"
                  @input="updateModel('postal_code', $event.target.value)"
                  type="text"
                  id="postal-code"
                  :class="inputClass('postal_code')"
                  :placeholder="this.$pgettext('Form postal code', '75002')"
                  :disabled="disabled"
                />
              </div>
              <p class="help is-danger" v-show="validation.hasError('value.postal_code')">
                {{ validation.firstError("value.postal_code") }}
              </p>
            </div>
          </div>
          <div class="column" :class="columnClass()">
            <div class="field">
              <label for="city" class="label"><translate>Ville</translate>*</label>
              <div class="control">
                <input
                  v-model="value.city"
                  @input="updateModel('city', $event.target.value)"
                  type="text"
                  id="city"
                  :class="inputClass('city')"
                  :placeholder="this.$pgettext('Form city', 'Paris')"
                  :disabled="disabled"
                />
              </div>
              <p class="help is-danger" v-show="validation.hasError('value.city')">
                {{ validation.firstError("value.city") }}
              </p>
            </div>
          </div>
          <div class="column is-6" v-if="value.countryCode === 'es' || isSpain(value.country)">
            <div class="field">
              <label for="state_province" class="label">
                <translate>État/Province</translate>
              </label>
              <div class="control">
                <input
                  v-model="value.state_province"
                  @input="updateModel('state_province', $event.target.value)"
                  type="text"
                  id="state_province"
                  class="input"
                  :placeholder="this.$pgettext('Form state province', 'Ile de France')"
                  :disabled="disabled"
                />
              </div>
            </div>
          </div>
          <div class="column is-6" v-if="value.countryCode === 'ie' || isIreland(value.country)">
            <div class="field">
              <label for="county_sublocality" class="label">
                <translate>District/Townland</translate>
              </label>
              <div class="control">
                <input
                  v-model="value.county_sublocality"
                  @input="updateModel('county_sublocality', $event.target.value)"
                  type="text"
                  id="county_sublocality"
                  class="input"
                  :placeholder="this.$pgettext('Address\' county or sublocality', 'Blackrock')"
                  :disabled="disabled"
                />
              </div>
            </div>
          </div>
          <div class="column" :class="columnClass()">
            <div class="field">
              <label for="country" class="label"><translate>Pays</translate>*</label>
              <div class="control">
                <input
                  v-model="value.country"
                  @input="
                    updateModel('country', $event.target.value);
                    updateModel('countryCode', null);
                  "
                  type="text"
                  id="country"
                  :class="inputClass('country')"
                  :placeholder="this.$pgettext('Form country', 'France')"
                  :disabled="disabled"
                />
              </div>
              <p class="help is-danger" v-show="validation.hasError('value.country')">
                {{ validation.firstError("value.country") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Places from "../AlgoliaPlaces";
import { ALGOLIA } from "../../lib/config";
import { Validator } from "simple-vue-validator";
import { posClient } from "../../lib/http/clients";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

const AUTHORIZED_CHARS_FOR_FIRST_AND_LAST_NAME_REGEX = /^[A-Za-zÀ-ÖØ-öø-ÿ-.'’ ]+$/;

export default {
  name: "CustomerDetails",
  props: ["value", "disabled"],
  components: { Places, VuePhoneNumberInput },

  validators: {
    "value.first_name"(value) {
      if (this.validation.resetting) return;
      return Validator.value(value)
        .required()
        .custom(() => {
          if (!value.match(AUTHORIZED_CHARS_FOR_FIRST_AND_LAST_NAME_REGEX)) {
            return this.$gettext("Prénom incorrect");
          }
        });
    },

    "value.last_name"(value) {
      if (this.validation.resetting) return;
      return Validator.value(value)
        .required()
        .custom(() => {
          if (!value.match(AUTHORIZED_CHARS_FOR_FIRST_AND_LAST_NAME_REGEX)) {
            return this.$gettext("Nom incorrect");
          }
        });
    },

    "value.email"(value) {
      if (this.validation.resetting) return;
      return Validator.value(value).required().email();
    },

    "value.line1"(value) {
      if (this.validation.resetting) return;
      return Validator.value(value).required();
    },

    "value.postal_code"(value) {
      if (this.validation.resetting) return;
      return Validator.value(value).required();
    },

    "value.city"(value) {
      if (this.validation.resetting) return;
      return Validator.value(value).required();
    },

    "value.country"(value) {
      if (this.validation.resetting) return;
      return Validator.value(value).required();
    },

    "value.phone": {
      validator(value) {
        if (this.validation.resetting) return;
        if (!value) return Validator.value("").required();
        return Validator.value(value)
          .required()
          .custom(() => {
            if (value.length >= 10) {
              return posClient
                .validatePhone(value)
                .then((res) => res.data)
                .then((data) => {
                  if (!data.is_valid) {
                    return this.$gettext("Numéro non valide");
                  }
                });
            } else {
              return this.$gettext("Numéro non valide");
            }
          });
      },
    },
  },

  data() {
    return {
      ALGOLIA,
      phone: this.$route.query.phone || null,
      inputPhoneFormatted: false,
      merchantCountry: this.$store.state.user.merchant.country,
    };
  },
  watch: {
    "$store.state.user.locale": async function () {
      if (Array.isArray(this.validation.errors)) {
        const fieldsToValidate = this.validation.errors.map((error) => error.field);
        this.$validate(fieldsToValidate);
      }
    },
  },
  computed: {
    translations: function () {
      return {
        countrySelectorLabel: this.$gettext("Code pays"),
        countrySelectorError: this.$gettext("Choisir un pays"),
        phoneNumberLabel: this.$gettext("Numéro de téléphone"),
        example: this.$gettext("Exemple"),
      };
    },
  },
  updated: function () {
    // Trigger function after render
    this.$nextTick(function () {
      this.formatInitialPhoneValue();
    });
  },
  methods: {
    checkIfPhoneStartsWithDialCode: function (phone, countries) {
      return countries.find((country) => {
        return phone.startsWith(country.dialCode);
      });
    },
    formatInitialPhoneValue: function () {
      if (this.$route.query.phone && !this.inputPhoneFormatted) {
        // If there is a phone query parameter, we check if we can set a clean input value
        const phoneToCheck = this.$route.query.phone.trim();
        const countryFound = this.checkIfPhoneStartsWithDialCode(
          phoneToCheck,
          this.$refs.phoneInput.codesCountries
        );
        // If a country is found, we update the flag input with the found one if it is different from the current one
        if (countryFound && this.$refs.phoneInput.countryCode !== countryFound.iso2) {
          this.$refs.phoneInput.countryCode = countryFound.iso2;
        }
        // If a country is found, we update the phone input with a formatted value if it is different from the current one
        if (
          countryFound &&
          this.$refs.phoneInput.$data.results.formatNational &&
          this.$refs.phoneInput.$data.results.formatNational !== this.$refs.phoneInput.value
        ) {
          this.$data.phone = this.$refs.phoneInput.$data.results.formatNational;
          this.inputPhoneFormatted = true;
        }
      }
    },
    focus() {
      this.$refs.firstName.scrollIntoView(false);
      this.$refs.firstName.focus();
    },
    isSpain(value) {
      if (!value) return false;
      return ["spain", "espana", "espagne", "spanien", "spagna", "spanje"].includes(
        value
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toLowerCase()
      );
    },
    isIreland(value) {
      if (!value) return false;
      return ["ireland", "irland", "irlanda", "irlande", "ierland"].includes(
        value
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "")
          .toLowerCase()
      );
    },
    updateModel(attr, value) {
      // The full object must be emitted for each update
      this.$store.dispatch("updateCurrentCustomer", { [attr]: value });
    },

    updateAddress(data) {
      if (!data || Object.keys(data).length === 0) {
        this.$store.dispatch("updateCurrentCustomer", {
          ...this.value,
          line1: null,
          postal_code: null,
          city: null,
          country: null,
          state_province: null,
          county_sublocality: null,
        });
        return;
      }

      const line1 = data.name;
      const postal_code = data.postcode;
      const country = data.country;
      const state_province = data.administrative;
      const countryCode = data.countryCode;
      const county_sublocality = data.county_sublocality;

      let city = data.city;
      if (city.toLowerCase().indexOf("arrondissement") >= 0) {
        city = city.split(" ")[0];
      }

      this.$store.dispatch("updateCurrentCustomer", {
        ...this.value,
        line1,
        postal_code,
        city,
        country,
        state_province,
        countryCode,
        county_sublocality,
      });

      // Not super sexy, but for better UX we need to tell the parent component that it can focus the
      // first field of the next form
      this.$emit("focusNext");
    },

    updatePhone(phoneObj) {
      this.updateModel("phone", phoneObj.e164 && phoneObj.e164.replace(/[^+0-9]/g, ""));
    },

    resetPhone() {
      Object.assign(this.$refs.phoneInput.$data, {
        ...this.$refs.phoneInput.$data,
        results: {},
        lastKeyPressed: null,
      });
      this.$refs.phoneInput.$emit("input", null);
      this.$refs.phoneInput.$emit("clear");
    },

    inputClass(field) {
      field = `value.${field}`;

      return {
        input: true,
        "is-danger": this.validation.hasError(field),
        "is-success": this.validation.isTouched(field) && !this.validation.hasError(field),
      };
    },

    columnClass() {
      return this.value.countryCode === "es" ||
        this.isSpain(this.value.country) ||
        this.value.countryCode === "ie" ||
        this.isIreland(this.value.country)
        ? "is-6"
        : "is-4";
    },

    headerTemplate() {
      const el = document.createElement("div");
      el.className = "autocomplete-header";
      el.innerText = this.$gettext("Fermer");

      el.onclick = () => {
        this.$refs.autocomplete.closeAutocomplete();
      };

      return el;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/bulma";

.autocomplete-header {
  padding: 10px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }
}

.vue-phone-number-input {
  & .select-country-container {
    flex: 0 0 0 !important;
    width: auto !important;
    min-width: auto !important;
    max-width: 100% !important;
    display: flex;
  }
  & .input-country-selector {
    display: flex;
    & .country-selector__country-flag {
      top: 30%;
    }
  }
  & .country-selector__toggle {
    top: calc(50% - 12px);
  }
  & .country-selector__country-flag {
    top: 12px;
  }
  & input {
    padding-top: 0 !important;
    cursor: text !important;
  }
  & label {
    display: none;
  }
}
</style>
