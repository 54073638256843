<template>
  <div
    class="card last-payment"
    v-if="this.$store.state.user.merchant.use_short_id_in_merchant_ref_and_show_on_pos && hasLastPayment"
  >
    <AlertPaymentNotDone v-if="!isPaymentDone" />
    <div class="card-content" v-if="isPaymentDone">
      <PaymentStatusIndicator :payment="lastPayment" class="last-payment-indicator" />
      <translate>Identifiant du dernier paiement</translate>
      (
      <strong> {{ lastPaymentCustomerName }}, {{ lastPaymentPurchaseAmount }} € </strong>,
      {{ lastPaymentTime }}
      )
      <translate>à copier/coller ou à scanner dans votre système de caisse</translate>
      <InputWithCopy :value="lastPaymentId" :disabled="!isPaymentDone" class="last-payment-id" highlighted />
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import InputWithCopy from "../InputWithCopy";
import PaymentStatusIndicator from "../PaymentStatusIndicator";
import AlertPaymentNotDone from "./AlertPaymentNotDone";
export default {
  name: "LastPaymentDetails",
  props: ["value", "disabled"],
  components: {
    PaymentStatusIndicator,
    InputWithCopy,
    AlertPaymentNotDone,
  },

  computed: {
    lastPayment() {
      return [...this.$store.state.payments].sort((p1, p2) => p2.created - p1.created)[0];
    },

    lastPaymentId() {
      return this.lastPayment.id.replace("payment_", "");
    },

    lastPaymentPurchaseAmount() {
      return this.lastPayment.purchase_amount / 100;
    },

    lastPaymentCustomerName() {
      return `${this.lastPayment.customer.first_name} ${this.lastPayment.customer.last_name}`;
    },

    lastPaymentTime() {
      return moment.unix(this.lastPayment.created).utc().calendar().toLowerCase();
    },

    hasLastPayment() {
      return !!this.lastPayment;
    },
    isPaymentDone() {
      return this.lastPayment.state === "paid" || this.lastPayment.state === "in_progress";
    },
  },
};
</script>

<style lang="scss">
.last-payment {
  margin-bottom: 15px;
}
.last-payment-id {
  margin-top: 10px;
}
.last-payment-indicator {
  margin-right: 7px;
}
</style>
