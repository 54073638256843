<template>
  <div class="export-buttons">
    <span v-bind:hidden="isNotAvailable">
      <a class="button center" v-bind:href="href" v-translate>Télécharger le fichier</a>
      &nbsp;
    </span>
    <button class="button center" @click="generatePaymentsExport" v-bind:disabled="isGenerating">
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PaymentsExportButton",
  computed: {
    isGenerating() {
      return this.$store.state.paymentsExportRequested;
    },

    isNotAvailable() {
      if (!this.isGenerating && this.$store.state.currentPaymentsExport) {
        return false;
      } else {
        return true;
      }
    },

    href() {
      if (!this.$store.state.paymentsExportRequested && this.$store.state.currentPaymentsExport) {
        return this.$store.state.currentPaymentsExport.url_csv;
      } else {
        return "#";
      }
    },

    buttonText() {
      if (!this.isNotAvailable) {
        return this.$gettext("Regénérer le fichier");
      } else if (this.isGenerating) {
        return this.$gettext("Génération en cours...");
      } else {
        return this.$gettext("Exporter le rapport journalier");
      }
    },
  },
  methods: {
    ...mapActions(["generatePaymentsExport"]),
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/scss/bulma";

.export-buttons {
  text-align: center;
}
</style>
