<template>
  <div class="notification is-warning is-light">
    <translate>La référence de commande est non accessible tant que le paiement n'est pas validé</translate>
  </div>
</template>
<script>
export default {
  name: "AlertPaymentNotDone",
};
</script>
