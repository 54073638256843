<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <slot name="title"></slot>
        </p>
      </header>

      <div class="content px-6 py-5 mb-0 modal-card-body">
        <slot></slot>
      </div>

      <footer v-if="hasButtons" class="modal-card-foot">
        <label v-if="requireConfirmation" class="checkbox">
          <input v-model="confirmed" type="checkbox" />
          <slot name="confirm-label"></slot>
        </label>

        <div class="flex-break"></div>

        <button v-if="canCancel" @click.prevent="$emit('cancel')" class="button">
          <slot name="cancel-label" v-translate>Annuler</slot>
        </button>

        <button
          v-if="canConfirm"
          @click.prevent="isValid && $emit('confirm')"
          :disabled="!isValid"
          class="button is-success"
        >
          <slot name="ok-label" v-translate>OK</slot>
        </button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",

  props: {
    hasButtons: {
      type: Boolean,
      default: true,
    },

    canCancel: {
      type: Boolean,
      default: false,
    },

    canConfirm: {
      type: Boolean,
      default: true,
    },

    requireConfirmation: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      confirmed: false,
    };
  },

  computed: {
    isValid() {
      return !this.requireConfirmation || this.confirmed;
    },
  },
};
</script>

<style scoped>
.flex-break {
  flex-basis: 100%;
  height: 20px;
}

.modal-card-foot {
  flex-wrap: wrap;
  justify-content: flex-end;
}

label > input[type="checkbox"] {
  margin-right: 5px;
}
</style>
