<template>
  <div class="payments-list-view">
    <button class="button is-pulled-right" @click="toggleShowPayments">X</button>
    <div class="title-payment-list">
      <h2 v-if="!anotherDate" v-html="this.$gettext('Paiements démarrés<br />Aujourd\'hui')"></h2>

      <h2 v-else>
        <translate>Paiements démarrés</translate> <br />
        <span v-if="!newDate"><translate>Aujourd'hui</translate></span
        ><span v-else>{{ displayedDate }}</span>
      </h2>
      <button class="btn button is-small" @click="showDatePicker">
        <i class="fa fa-calendar" v-if="!anotherDate" />
        <i class="fa fa-clock" v-else />
      </button>
    </div>

    <div class="pick-a-date-area" v-if="anotherDate">
      <input
        type="date"
        class="input"
        @change="submitNewDate"
        v-model="newDate"
        :min="getDateOneYearBefore()"
        :max="getYesterdayAsString()"
      />
    </div>

    <div>
      <PaymentsExportButton v-if="!anotherDate" />
      <PaymentsList :paymentsFromDate="fetchedPayments" />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import PaymentsList from "../components/payments_list/PaymentsList";
import PaymentsExportButton from "../components/payments_list/PaymentsExportButton";
import { getPaymentsFromDate } from "@/lib/payments";
import moment from "moment";
export default {
  name: "PaymentsListView",
  components: { PaymentsList, PaymentsExportButton },

  data() {
    return {
      anotherDate: false,
      fetchedPayments: null,
      newDate: null,
      debounce: null,
    };
  },

  mounted() {
    this.newDate = null;
  },
  computed: {
    displayedDate() {
      return moment(this.newDate).format("LL");
    },
  },
  methods: {
    ...mapActions(["toggleShowPayments"]),
    showDatePicker() {
      this.anotherDate = !this.anotherDate;

      // We reset the paiement list to display today's paiments
      if (!this.anotherDate) {
        this.fetchedPayments = null;
        this.newDate = null;
      }
    },
    async submitNewDate() {
      // HTML max attribute is not enough to avoid user to manually set date in future
      if (moment(this.newDate).isAfter() || moment(this.newDate).isSame(new Date(), "day")) {
        this.newDate = this.getYesterdayAsString();
        // So we just set the date to yesterday and return to avoid api call
        return false;
      }
      // Debounce to avoid flooding the backend.
      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        const paymentsFromDate = await getPaymentsFromDate(
          moment(this.newDate).startOf("day").hour(12).unix()
        );
        this.fetchedPayments = paymentsFromDate.data;
      }, 300);
    },
    // To avoid dates in the future on the datepicker
    getYesterdayAsString() {
      const yesterday = moment().subtract(1, "days").startOf("day");
      return moment(yesterday).format("YYYY-MM-DD");
    },
    // To fix a limit for the minimum value of the datepicker
    getDateOneYearBefore() {
      const oneYearBefore = moment().subtract(1, "years");
      return moment(oneYearBefore).format("YYYY-MM-DD");
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  float: right;
  display: block;
  margin-top: -30px;
  margin-right: 0;
}

h2 {
  margin-top: 30px;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
}

h3 {
  margin-bottom: 30px;
  text-align: center;
}

.pick-a-date-area {
  display: flex;
  gap: 10px;
}

.title-payment-list {
  margin-bottom: 10px;
}
</style>
