<template>
  <input
    type="text"
    :value="value"
    @input="updateValue($event.target.value)"
    autocomplete="off"
    spellcheck="false"
  />
</template>

<script>
// Algolia Places doesn't exist anymore. This component is now a simple input without any autocomplete.
// It has been replaced by Mapbox in the new POS.
// https://www.algolia.com/blog/product/sunsetting-our-places-feature/

export default {
  name: "AlgoliaPlaces",
  props: {
    value: {
      type: String,
      default: "",
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    },
  },
  mounted() {
    this.options.container = this.options.container || this.$el;
    const inputIcons = this.options.container.parentElement.querySelectorAll("button.ap-input-icon");
    for (let btn of inputIcons) {
      btn.setAttribute("tabindex", "-1");
    }
  },
};
</script>
