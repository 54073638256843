<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title" v-translate>Détails du paiement</p>
      <p class="card-header-icon">
        <span class="icon">
          <i class="far fa-credit-card"></i>
        </span>
      </p>
    </header>
    <div class="card-content">
      <div class="content">
        <PurchaseWithoutInstallments
          v-if="customerChooseInstallments"
          ref="paymentRef"
          v-model="value"
          :disabled="disabled"
        ></PurchaseWithoutInstallments>
        <PurchaseWithInstallments
          v-else
          ref="paymentRef"
          v-model="value"
          :disabled="disabled"
        ></PurchaseWithInstallments>
        <div class="bancontact_info" v-if="shouldDisplayBancontactMention" v-translate>
          Pour utiliser Bancontact, assurez-vous d'avoir votre Digipass
        </div>
        <section class="meta fields">
          <div v-if="canDisableCustomerFees" class="field">
            <input
              v-model="disable_customer_fees"
              name="disable_customer_fees"
              id="disable_customer_fees"
              @input="updateApplyCustomerFees($event.target.checked)"
              type="checkbox"
              :class="['customer_fee_checkbox']"
            />
            <label for="disable_customer_fees" v-translate>Offrir les frais de paiement au client</label>
          </div>
          <div class="field">
            <label for="reference" class="label">
              <translate>Référence de commande</translate><span v-if="orderReferenceRequired">*</span>
            </label>
            <small v-if="orderReferenceTip">
              {{ orderReferenceTip }}
            </small>
            <div class="control">
              <input
                :value="value.order.merchant_reference"
                @input="updateOrder('merchant_reference', $event.target.value)"
                type="text"
                :class="['input', { 'is-danger': validation.hasError('value.order.merchant_reference') }]"
                name="reference"
                id="reference"
                :disabled="this.$store.state.user.merchant.use_short_id_in_merchant_ref_and_show_on_pos"
              />
              <p class="help is-danger" v-show="validation.hasError('value.order.merchant_reference')">
                {{ validation.firstError("value.order.merchant_reference") }}
              </p>
            </div>
          </div>

          <div class="field">
            <label for="comment" class="label" v-translate>Commentaire</label>
            <div class="control">
              <textarea
                :value="value.order.comment"
                @input="updateOrder('comment', $event.target.value)"
                name="comment"
                id="comment"
                rows="5"
                class="textarea"
              ></textarea>
            </div>
          </div>
          <PaymentCustomField
            ref="fields"
            v-for="field in customFields"
            v-bind:key="field.key"
            :name="field.key"
            :label="field.label"
            :required="field.required"
            :desc="field.desc"
            :regex="field.regex"
            :disabled="disabled"
            @input="updateCustomData(field.key, $event)"
          />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleVueValidation from "simple-vue-validator";
import PaymentCustomField from "./PaymentCustomField";
import PurchaseWithInstallments from "./PurchaseWithInstallments";
import PurchaseWithoutInstallments from "./PurchaseWithoutInstallments";

const Validator = SimpleVueValidation.Validator.create();

export default {
  name: "PaymentDetails",
  components: { PurchaseWithoutInstallments, PurchaseWithInstallments, PaymentCustomField },
  props: ["value", "disabled"],
  data: function () {
    return {
      disable_customer_fees: false,
    };
  },
  watch: {
    "$store.state.user.locale": async function () {
      if (Array.isArray(this.validation.errors)) {
        const fieldsToValidate = this.validation.errors.map((error) => error.field);
        this.$validate(fieldsToValidate);
      }
    },
  },
  methods: {
    focus() {
      if (!this.$refs.purchaseAmount) {
        return;
      }
      this.$refs.purchaseAmount.scrollIntoView(true);
      this.$refs.purchaseAmount.focus();
    },

    updateModel(attr, value) {
      // The full object must be emitted for each update
      return this.$store.dispatch("updateCurrentPayment", { [attr]: value });
    },

    updateOrder(attr, value) {
      return this.updateModel("order", { ...this.value.order, [attr]: value });
    },

    updateCustomData(attr, value) {
      return this.updateModel("custom_data", { ...this.value.custom_data, [attr]: value });
    },

    updateApplyCustomerFees(value) {
      this.disable_customer_fees = value;
      if (value) {
        this.updateModel("customer_fee_variable", 0);
      } else {
        // customer_fees are enabled, let the api use the default customer_fee_variable value
        this.updateModel("customer_fee_variable", undefined);
      }
    },
  },

  validators: {
    "value.purchase_amount"() {
      return Validator.custom(async () => {
        // Wait that the dom is updated
        await this.$nextTick();
        return !(await this.$refs.paymentRef.$validate());
      });
    },
    "value.order.merchant_reference"(value) {
      return this.orderReferenceRequired && Validator.value(value).required();
    },
    "value.custom_data"() {
      if (this.validation.resetting) return;
      if (!this.$refs.fields) {
        return Validator.value(true);
      }
      const check = this.$refs.fields
        .map(function (field) {
          return field.validation.errors.length === 0 && (!field.required || !!field.value.trim());
        })
        .reduce(function (a, b) {
          return a && b;
        }, true);

      return Validator.value(check || "").required();
    },
  },

  computed: {
    canDisableCustomerFees() {
      return (
        this.$store.state.user &&
        this.$store.state.user.can_disable_customer_fees &&
        this.$store.state.currentPayment.installments_count <= 4
      );
    },

    orderReferenceRequired() {
      return this.$store.state.user ? this.$store.state.user.merchant.order_reference.required : false;
    },

    orderReferenceTip() {
      return this.$store.state.user ? this.$store.state.user.merchant.order_reference.tip : null;
    },
    customFields() {
      return this.$store.state.user ? this.$store.state.user.merchant.custom_data_template : {};
    },

    /**
     * Return true if the user can choose installments else false (default false)
     * @returns {boolean}
     */
    customerChooseInstallments() {
      return this.$store.state.user.merchant.customer_choose_installments;
    },
    shouldDisplayBancontactMention() {
      const providerLowerCase =
        this.$store.state.user.terminals.length > 0 && this.$store.state.user.terminals[0].provider
          ? this.$store.state.user.terminals[0].provider.toLowerCase()
          : "";
      return this.$store.state.user && this.$store.state.user.terminals.length > 0
        ? this.$store.state.user.merchant.country === "BE" && providerLowerCase === "adyen"
        : false;
    },
  },
};
</script>

<style scoped>
.customer_fee_checkbox {
  height: 15px;
  width: 15px;
  margin: 5px;
}
.bancontact_info {
  color: #707070;
  font-size: 80%;
  padding: 8px 0;
}
</style>
