<template>
  <section class="purchase">
    <translate>Le client souhaite payer</translate> &nbsp;
    <input
      ref="purchaseAmount"
      :value="value.purchase_amount_input"
      @input="updatePurchaseAmount($event.target.value)"
      type="text"
      :class="purchaseAmountClasses"
      :disabled="disabled"
    />
    €
  </section>
</template>

<script>
import PaymentMixin from "../../mixins/paymentMixin";
import SimpleVueValidation from "simple-vue-validator";

const Validator = SimpleVueValidation.Validator.create();

export default {
  name: "PurchaseWithoutInstallments",
  mixins: [PaymentMixin],
  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  mounted() {
    this.value.installments_count = null;
  },
  watch: {
    "$store.state.user.locale": async function () {
      if (Array.isArray(this.validation.errors)) {
        const fieldsToValidate = this.validation.errors.map((error) => error.field);
        this.$validate(fieldsToValidate);
      }
    },
  },

  validators: {
    "value.purchase_amount"(value) {
      const minAmount = this.$store.state.user.merchant.minimum_purchase_amount;
      const maxAmount = this.$store.state.user.merchant.maximum_purchase_amount;

      // Use the value in euros so that error message displays euros as well
      return Validator.value(Number(value))
        .required()
        .between(
          minAmount,
          maxAmount,
          this.$gettextInterpolate(
            this.$gettext("Le montant doit être compris entre %{minAmount} € et %{maxAmount} €."),
            { minAmount: minAmount / 100, maxAmount: maxAmount / 100 }
          )
        );
    },
  },
};
</script>

<style scoped>
.is-inline {
  vertical-align: baseline;
}

.purchase-amount {
  width: 100px !important;
}

section.purchase {
  margin-bottom: 2rem;
}
</style>
