<template>
  <div class="top-banner-admin">
    Staff member
    <abbr :title="realUser.email">{{ realUser.name }}</abbr>
    logged in as user
    <abbr :title="user.email">{{ user.name }}</abbr>
    of merchant
    <abbr :title="merchant.id">{{ merchant.name }}</abbr> -
    <a :href="dashboardUrl">Return to admin dashboard</a>
  </div>
</template>

<script>
import { DASHBOARD_URL } from "../lib/config";

export default {
  name: "StaffBanner",
  props: {
    user: {
      type: Object,
      required: true,
    },
    realUser: {
      type: Object,
      required: true,
    },
  },

  computed: {
    merchant() {
      return this.user ? this.user.merchant : { name: "", id: "" };
    },

    dashboardUrl() {
      return DASHBOARD_URL;
    },
  },
};
</script>

<style lang="scss" scoped>
.top-banner-admin {
  width: 100%;
  background: repeating-linear-gradient(45deg, firebrick, firebrick 10px, darkred 10px, darkred 20px);
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.top-banner-admin a {
  color: #ccc;
  text-decoration: underline;
}
</style>
