<template>
  <p class="control">
    <a class="button copy is-medium" :class="{ disabled: disabled }" @click="copy" title="Copier">
      <i v-if="copySuccess" class="fas fa-check"></i>
      <i v-else class="fas fa-copy"></i>
    </a>
  </p>
</template>

<script>
export default {
  name: "CopyButton",
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      copySuccess: false,
    };
  },

  methods: {
    async copy() {
      if (this.disabled) return false;
      if (!navigator.clipboard) {
        // Deprecated way to do the copy
        this.$refs.input.focus();
        this.$refs.input.select();
        document.execCommand("copy");
      } else {
        await navigator.clipboard.writeText(this.value);
      }

      this.copySuccess = true;
      setTimeout(() => (this.copySuccess = false), 2000);
    },
  },
};
</script>

<style scoped>
.disabled {
  color: #e6e4e4 !important; /*bulma is overriding button:hover*/
  border-color: #e6e4e4 !important; /*bulma is overriding button:hover*/
  cursor: not-allowed;
}
</style>
