<template>
  <nav class="level px-4 py-3 has-background-white">
    <div class="level-left">
      <a href="/" class="level-item">
        <img class="image merchant-logo" v-if="logo_url" :src="logo_url" :alt="merchant_name" />
        <h2 v-else>{{ merchant_name }}</h2>
        <span v-if="!prod" class="tag is-warning">sandbox</span>
      </a>
      <LocaleDropdown class="level-item" :user="user" />
    </div>
    <div class="level-right">
      <div class="level-item">
        <div class="columns is-mobile is-multiline is-vcentered is-gapless">
          <div class="column has-text-right has-text-centered-mobile has-text-grey has-text-weight-semibold">
            {{ user.name }}
          </div>
          <AccountDropdown class="column" :user="user" />
          <div class="column is-full-mobile">
            <button class="button is-fullwidth" @click="toggleShowPayments">
              <translate>Voir les paiements</translate>
            </button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions } from "vuex";
import AccountDropdown from "./AccountDropdown";
import LocaleDropdown from "./LocaleDropdown";
import { IS_PROD } from "@/lib/config";

export default {
  name: "MainMenu",
  props: ["user"],
  components: { AccountDropdown, LocaleDropdown },

  data() {
    return {
      prod: IS_PROD,
    };
  },

  methods: {
    ...mapActions(["toggleShowPayments"]),
  },

  computed: {
    logo_url() {
      return this.$store.state.user ? this.$store.state.user.merchant.logo_url : "";
    },

    merchant_name() {
      return this.$store.state.user ? this.$store.state.user.merchant.name : "";
    },

    payments() {
      return this.$store.state.payments;
    },
  },
};
</script>

<style lang="scss" scoped>
.payments-status {
  margin-right: 10px;
}

.tag {
  padding: 5px 10px;
  font-weight: bold;
}

.merchant-logo {
  max-height: 48px;
  max-width: 128px;
}
</style>
