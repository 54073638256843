<template>
  <div>
    <ul class="payments-list">
      <div v-if="paymentsFromDate">
        <PaymentItem v-for="payment in paymentsFromDate" :key="payment.id" :payment="payment" :now="now" />
      </div>
      <div v-else>
        <PaymentItem v-for="payment in payments" :key="payment.id" :payment="payment" :now="now" />
      </div>
    </ul>

    <div
      class="notification is-info mt-3"
      v-if="
        (!paymentsFromDate && payments.length === 0) || (paymentsFromDate && paymentsFromDate.length === 0)
      "
    >
      <translate>Aucun paiement à cette date</translate>
    </div>
  </div>
</template>

<script>
import * as moment from "moment";
import PaymentItem from "./PaymentItem";

export default {
  name: "PaymentsList",
  components: { PaymentItem },
  props: {
    paymentsFromDate: null,
  },
  data() {
    return {
      now: moment.utc(),
    };
  },

  mounted() {
    // Update time reference every minute to update relative time in children items
    setInterval(() => (this.now = moment.utc()), 60000);
  },

  computed: {
    payments() {
      return [...this.$store.state.payments].sort((p1, p2) => p2.created - p1.created);
    },
  },
};
</script>

<style scoped lang="scss"></style>
