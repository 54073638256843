<template>
  <div id="app">
    <LoadingOverlay v-if="loading" />
    <template v-else>
      <LoginView v-if="!isLoggedIn" />
      <router-view v-else />
    </template>
  </div>
</template>

<script>
import store from "./store";
import LoginView from "./views/Login";
import LoadingOverlay from "./components/LoadingOverlay";

export default {
  name: "app",
  store,
  components: { LoginView, LoadingOverlay },

  data() {
    return {
      loading: true,
    };
  },

  async mounted() {
    await this.$store.dispatch("loadUser");
    if (this.$store.timer == null) {
      await this.$store.dispatch("startPollTimer");
    }

    this.loading = false;
  },

  beforeDestroy() {
    this.$store.dispatch("stopPollTimer");
  },

  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    user() {
      return this.$store.state.user;
    },
  },
};
</script>

<style lang="scss">
#app {
}
</style>
