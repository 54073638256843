<template>
  <div class="create-payment">
    <StaffBanner v-if="realUser !== null" :user="user" :realUser="realUser" />

    <MainMenu v-if="user !== null" :user="user" />

    <div v-if="error" class="notification is-danger">
      <button class="delete" @click="dismissError"></button>
      <span v-if="errors.length > 1" v-translate>Merci de corriger les erreurs suivantes :</span>
      <ul class="errors">
        <li v-for="(error, idx) in errors" :key="idx">
          {{ error }}
        </li>
      </ul>
    </div>

    <section class="main section is-fullheight is-light pt-4">
      <transition name="slide-payments">
        <PaymentsListView v-if="showPayments" />
      </transition>

      <TerminalFlowModal
        v-if="displayTerminalFlow"
        :flow-state="terminalFlowState"
        @forceUnlock="forceUnlockTerminal"
        @cancel="resetTerminalFlow"
        @retry="retryTerminalFlow"
        @success="finalizeTerminalFlow"
      />

      <RequestIdCopyModal
        v-if="requestIdCopy"
        @cancel="idCopyAcquired = false"
        @confirm="idCopyAcquired = true"
      />

      <div class="container">
        <LastPaymentDetails />

        <div class="columns">
          <div class="column">
            <CustomerDetails
              ref="customerDetails"
              v-model="customer"
              @focusNext="focusPaymentDetails"
              :disabled="disableFields"
            />
          </div>
          <div class="column">
            <div class="panel panel-default">
              <B2BDetails
                ref="b2BDetails"
                v-model="customer"
                :disabled="disableFields"
                v-if="enableB2BPayment"
              />
            </div>
            <PaymentDetails ref="paymentDetails" v-model="payment" :disabled="disableFields" />

            <div class="columns mt-4 mb-4">
              <div class="column is-hidden-mobile"></div>
              <div class="column is-full-mobile is-narrow-tablet">
                <button
                  @click="resetPayment"
                  :class="['button', 'is-fullwidth', 'is-medium', { 'is-loading': loading }]"
                  :disabled="loading"
                >
                  <translate>Réinitialiser les formulaires</translate>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <PaymentBar
      ref="paymentBar"
      :payment="payment"
      :canCreatePayment="canCreatePayment"
      :loading-target="loadingTarget"
      @submit="createPayment"
    />
  </div>
</template>

<script>
import { createPayment, sendPaymentToSMS } from "../lib/payments";
import { POS_URL, ADYEN_GATEWAY_WS_URL } from "../lib/config";
import { ExpiredSessionError, ValidationError } from "../lib/http/errors";

import MainMenu from "../components/MainMenu";
import StaffBanner from "../components/StaffBanner";
import PaymentsListView from "./PaymentsListView";
import PaymentBar from "../components/payment/PaymentBar";
import CustomerDetails from "../components/payment/CustomerDetails";
import B2BDetails from "../components/payment/B2BDetails";
import LastPaymentDetails from "../components/payment/LastPaymentDetails";
import PaymentDetails from "../components/payment/PaymentDetails";
import RequestIdCopyModal from "./RequestIdCopyModal";
import TerminalFlowModal from "./TerminalFlowModal";
import PaymentMixin from "../mixins/paymentMixin";

const DEFAULT_TERMINAL_FLOW_STATE = {
  status: "pending",
  steps: [],
};

export default {
  name: "CreatePayment",
  mixins: [PaymentMixin],
  components: {
    PaymentBar,
    StaffBanner,
    MainMenu,
    B2BDetails,
    CustomerDetails,
    LastPaymentDetails,
    PaymentDetails,
    PaymentsListView,
    RequestIdCopyModal,
    TerminalFlowModal,
  },

  data() {
    return {
      loading: false,
      loadingTarget: null,
      error: false,
      errors: null,
      canCreatePayment: false,
      displayTerminalFlow: false,
      requestIdCopy: null,
      idCopyAcquired: null,
      terminalFlowState: DEFAULT_TERMINAL_FLOW_STATE,
    };
  },

  created() {
    this.loadLastTerminal();
  },

  mounted() {
    this.customerArgsUpdate();
    this.paymentArgsUpdate();
  },

  methods: {
    loadLastTerminal() {
      this.$store.dispatch("loadTerminal");
    },

    customerArgsUpdate() {
      const argNames = [
        `first_name`,
        `last_name`,
        `email`,
        `phone`,
        `line1`,
        `line2`,
        `postal_code`,
        `city`,
        `state_province`,
        `country`,
      ];
      this.$store.dispatch("updateCurrentCustomer", this.argsToObject(argNames));
    },
    paymentArgsUpdate() {
      const argPurchaseAmount = new URL(location.href).searchParams.get(`purchase_amount`);
      if (argPurchaseAmount) {
        this.updatePurchaseAmount(argPurchaseAmount);
      }
      const argMerchantReference = new URL(location.href).searchParams.get(`merchant_reference`);
      if (argMerchantReference) {
        this.updateMerchantReference(argMerchantReference);
      }
    },

    updateMerchantReference(merchantReference) {
      return this.$store.dispatch("updateCurrentPayment", {
        order: {
          ...this.customer.order,
          merchant_reference: merchantReference,
        },
      });
    },

    /**
     * Returns a key value object with key the argname and value the argvalue
     * @param {[string]} argNames
     * @returns {{}}
     */
    argsToObject(argNames) {
      const urlObj = new URL(location.href);
      const argFieldsValue = {};
      for (const arg of argNames) {
        const argVal = urlObj.searchParams.get(arg);
        if (!this.customer[arg] && argVal) {
          argFieldsValue[arg] = argVal;
        }
      }
      return argFieldsValue;
    },
    focusPaymentDetails() {
      this.$refs.paymentDetails.focus();
    },

    async createPayment(target) {
      this.error = false;
      this.loading = true;
      this.loadingTarget = target;

      if (!this.payment.id) {
        let return_url, customer_cancel_url;
        const { order: orderData, ...paymentData } = this.payment;

        // Users completing an SMS/email-sent payment will be redirected to the ongoing payment page after
        // success. But because we can't know the payment's ID before it's created (and can't change the
        // return_url afterwards), we use a /redirect endpoint on the POS service to "proxy" the redirect.
        return_url = `${POS_URL}/redirect`;
        customer_cancel_url = null;

        if (target === "device") {
          return_url = window.location.origin + this.$router.resolve({ name: "payment_success" }).href;
          customer_cancel_url =
            window.location.origin + this.$router.resolve({ name: "create_payment" }).href;
        } else if (target === "terminal") {
          // We need to create a NOT_READY payment, as the customers will be able to opt for
          // p3x or p4x themselves on the terminal
          paymentData.installments_count = null;

          /*
                        TODO: Do better once we have more insight into the kind of "offline requirements" the
                              Risks team could need
                     */
          if (paymentData.purchase_amount >= 100000) {
            try {
              await this.requireOfflineIdCopy();
            } catch (Exception) {
              this.loading = false;
              this.loadingTarget = null;
              return;
            }

            // Save information in custom data, that a copy of the identity document has
            // been acquired
            paymentData.custom_data = {
              ...(paymentData.custom_data || {}),
              confirmedOfflineIdCopy: true,
            };
          }
        }

        try {
          const payment = await createPayment(
            this.customer,
            paymentData,
            orderData,
            return_url,
            customer_cancel_url,
            target,
            this.user.locale
          );
          await this.$store.dispatch("updateCurrentPayment", payment);
          await this.$store.dispatch("pollAllPayments");
        } catch (e) {
          this.error = true;

          if (e instanceof ValidationError) {
            this.errors = e.errorsList();
          } else if (e instanceof ExpiredSessionError) {
            // Expired sessions should not display an error
            this.error = false;
            this.loading = false;
            this.loadingTarget = null;
          } else {
            this.errors = [
              this.$gettext(
                "Une erreur est survenue. Merci de réessayer ou de nous contacter si le problème persiste."
              ),
            ];
          }
        }
      }

      if (!this.error) {
        switch (target) {
          case "sms":
            try {
              await sendPaymentToSMS(this.payment);
            } catch (e) {
              this.error = true;
            }
            break;
          case "device":
            // Redirect to payment page
            window.location.href = this.payment.url;
            return;
          case "terminal":
            // Start terminal flow
            this.startTerminalFlow();
            break;
          default:
            break;
        }
      }

      this.loading = false;
      this.loadingTarget = null;
    },

    startTerminalFlow() {
      this.displayTerminalFlow = true;

      const terminalProvider = this.$store.state.currentTerminalProvider;
      const terminalRef = this.$store.state.currentTerminalRef;
      const url = new URL(`${ADYEN_GATEWAY_WS_URL}/terminals/handle-payment`);

      url.searchParams.append("payment_id", this.payment.id);
      url.searchParams.append("terminal_provider", terminalProvider);
      url.searchParams.append("terminal_provider_ref", terminalRef);
      this.terminalWebSocket = new WebSocket(url);

      this.terminalWebSocket.onmessage = (event) => {
        this.terminalFlowState = JSON.parse(event.data);
      };

      this.terminalWebSocket.onclose = async (event) => {
        if (event.code === 4403) {
          // Auth error
          await this.$store.dispatch("handleExpiredSession");
        } else if (this.terminalFlowState.status === "ongoing") {
          this.terminalFlowState.status = "retry";

          const pendingIndex = this.terminalFlowState.steps.findIndex((step) => step.status === "pending");

          let errorIndex = pendingIndex - 1;
          if (pendingIndex === -1) {
            errorIndex = this.terminalFlowState.steps.length - 1;
          } else if (pendingIndex === 0) {
            errorIndex = 0;
          }

          this.terminalFlowState.steps[errorIndex].status = "failure";
          this.terminalFlowState.steps[errorIndex].error = {
            message: this.$gettext("Connexion perdue. Merci de réessayer."),
          };
        }
      };
    },

    forceUnlockTerminal() {
      this.terminalWebSocket.send(JSON.stringify({ action: "force" }));
    },

    retryTerminalFlow() {
      this.resetTerminalFlow(false);
      this.startTerminalFlow();
    },

    resetTerminalFlow(resetPayment = true) {
      this.displayTerminalFlow = false;
      this.terminalFlowState = DEFAULT_TERMINAL_FLOW_STATE;

      if (this.terminalWebSocket) {
        this.terminalWebSocket.close(1000);
      }

      if (resetPayment) {
        this.canCreatePayment = true;
        this.loadingTarget = null;
        this.$store.dispatch("updateCurrentPayment", { ...this.payment, id: null });
      }
    },

    finalizeTerminalFlow() {
      this.resetTerminalFlow(false);
      this.resetPayment();
    },

    resetPayment() {
      this.$refs.customerDetails.resetPhone();
      this.$refs.customerDetails.validation.reset();
      this.$refs.paymentDetails.validation.reset();
      this.$refs.paymentBar.reset();

      this.canCreatePayment = false;
      this.displayTerminalFlow = false;
      this.$store.dispatch("resetPayment");

      this.$refs.customerDetails.focus();
    },

    dismissError() {
      this.error = false;
      this.errors = null;
    },

    _checkDataValidity() {
      // Use $nextTick to run validity checks at next event loop, so that all values have been updated for sure
      this.$nextTick(async () => {
        try {
          this.canCreatePayment = await Promise.all([
            this.$refs.customerDetails.$validate(),
            this.$refs.paymentDetails.$validate(),
          ]).then((validities) => validities.reduce((prev, curr) => prev && curr));
        } catch (e) {
          // just pass
        }
      });
    },

    requireOfflineIdCopy() {
      this.requestIdCopy = true;
      this.idCopyAcquired = null;

      return new Promise((resolve, reject) => {
        const unwatch = this.$watch("idCopyAcquired", (newValue) => {
          unwatch();
          this.requestIdCopy = false;

          if (newValue === true) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },
  },

  computed: {
    user() {
      return this.$store.state.user;
    },

    payment() {
      return this.$store.state.currentPayment;
    },

    customer() {
      return this.$store.state.currentCustomer;
    },

    disableFields() {
      return !!this.$store.state.currentPayment.id;
    },

    enableB2BPayment() {
      return this.$store.state.enableB2BPayment;
    },

    showPayments() {
      return this.$store.state.showPayments;
    },

    realUser() {
      return this.$store.state.user ? this.$store.state.user.real_user : null;
    },
  },
  watch: {
    payment() {
      this._checkDataValidity();
    },

    customer: {
      immediate: true,
      handler() {
        this._checkDataValidity();
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/bulma.scss";

.create-payment {
  height: auto;
  min-height: 100vh;
  padding-bottom: 2 * $navbar-height;
  background-color: $light;
}

nav {
  box-shadow: 0 1px 5px 0 $grey-lighter;
}

.payments-list-view {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  max-width: 450px;
  width: 100%;

  box-shadow: 0 1px 5px 0 $grey-lighter;

  padding: 2rem 30px 2rem;

  background: white;

  overflow: auto;
  z-index: 40;
}

.slide-payments-enter-active,
.slide-payments-leave-active {
  transition: right 0.3s ease-in-out;
}

.slide-payments-enter,
.slide-payments-leave-to {
  right: -450px;
}
</style>
