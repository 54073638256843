/*
Not using lib/http/clients here because:
1. During login/logout operations we don't want the niceties brought by the class (CSRF token injection and
   expired session handling)
2. Importing the module from here would create a circular dependency (store -> auth -> clients -> store)
   making `store` unavailable to `HttpClient` as a result
 */
import axios from "axios";
import { apiClient as api, posClient as pos } from "./http/clients";
import { POS_URL } from "./config";

import { translate } from "vue-gettext";
const { gettext: $gettext } = translate;

export function storeLoggedUser(user_data) {
  localStorage.setItem("user_data", JSON.stringify(user_data));
}

export function removeLoggedUser() {
  localStorage.removeItem("user_data");
}

export function login(user) {
  return axios.post(`${POS_URL}/login`, user).then((response) => {
    if (response.data.redirect) {
      document.location.href = response.data.redirect;
      let error = new Error("OpenID Login");
      error.response = { data: { message: $gettext("Redirection vers Google en cours") } };
      throw error;
    } else {
      return response.data;
    }
  });
}

export function switchAccount(merchantId) {
  const data = { merchant_external_id: merchantId };
  return api.post("/accounts/session", data).then((response) => response.success);
}

export function currentUser() {
  return JSON.parse(localStorage.getItem("user_data")) || {};
}

export function updateServerLocale(externalUserId, newLocale) {
  return api.post(`/users/${externalUserId}`, { locale: newLocale }).then((response) => response.success);
}

export function fetchUserData() {
  return axios.get(`${POS_URL}/me`).then((response) => response.data);
}

export function logout() {
  return axios.post(`${POS_URL}/logout`);
}

export function getZendeskToken() {
  return pos.get("/zendesk/token").then((response) => response.data.token);
}
