<template>
  <div class="card">
    <header class="card-header">
      <p class="card-header-title" v-translate>Informations entreprise</p>
      <p class="card-header-icon">
        <span class="icon">
          <i class="fas fa-building"></i>
        </span>
      </p>
    </header>
    <div class="card-content">
      <div class="fields content">
        <!-- First & last names -->
        <div class="columns">
          <div class="column">
            <div class="field">
              <label for="first-name" class="label">
                <translate>SIREN</translate>&nbsp;<translate>(9 chiffres)</translate>
              </label>
              <div class="control">
                <input
                  v-model="value.business_id_number"
                  @input="updateSIREN($event)"
                  type="text"
                  :class="inputClass('business_id_number')"
                  id="business_id_number"
                  :disabled="disabled"
                  ref="business_id_number"
                />
              </div>
              <p class="help is-danger" v-show="validation.hasError('value.business_id_number')">
                {{ validation.firstError("value.business_id_number") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "simple-vue-validator";
import { prettifySIREN, validateSIREN } from "@/lib/payments";

export default {
  name: "B2BDetails",
  props: ["value", "disabled"],
  components: {},

  validators: {
    "value.business_id_number"(value) {
      if (this.validation.resetting) return;
      return Validator.value(value).custom(() => {
        if (value.length > 0 && !validateSIREN(value))
          return this.$pgettext("PoS/Input Form", "Numéro SIREN invalide");
      });
    },
  },

  methods: {
    updateSIREN(ev) {
      var val = prettifySIREN(ev.target.value);
      ev.target.value = val;
      this.$store.dispatch("updateCurrentCustomer", { ["business_id_number"]: val });
    },

    inputClass(field) {
      field = `value.${field}`;

      return {
        input: true,
        "is-danger": this.validation.hasError(field),
        "is-success": this.validation.isTouched(field) && !this.validation.hasError(field),
      };
    },

    headerTemplate() {
      const el = document.createElement("div");
      el.className = "autocomplete-header";
      el.innerText = this.$gettext("Fermer");

      el.onclick = () => {
        this.$refs.autocomplete.closeAutocomplete();
      };

      return el;
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/bulma";

.autocomplete-header {
  padding: 10px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: #efefef;
  }
}
</style>
