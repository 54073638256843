<template>
  <div class="login-view">
    <section class="hero is-fullheight is-light">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-one-third-desktop is-two-thirds-tablet">
              <div class="content has-text-centered">
                <img src="../assets/logo.svg" alt="Alma" width="250" />
              </div>

              <LoginCard />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LoginCard from "../components/LoginCard";

export default {
  name: "Login",
  components: {
    LoginCard,
  },
};
</script>

<style scoped>
.logo {
  height: 40px;
}

.login-view {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 9999;
}
</style>
