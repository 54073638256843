<template>
  <div class="payment-success">
    <section class="hero is-medium is-bold">
      <section class="hero-body">
        <div class="container">
          <div class="content">
            <img src="../assets/logo.svg" alt="Alma" width="100" />
          </div>
          <h1 class="title" v-translate>Merci !</h1>
          <h2 class="subtitle" v-translate>Votre paiement a bien été enregistré.</h2>
        </div>
      </section>
      <section class="hero-body">
        <div class="container" v-translate="{ timeout: timeout }" translate-context="s for seconds">
          Redirection dans {{ timeout }}s
        </div>
      </section>
    </section>
  </div>
</template>

<script>
export default {
  name: "Success",

  created() {
    this.interval = setInterval(() => {
      this.timeout -= 1;

      if (this.timeout === 0) {
        clearInterval(this.timeout);
        window.location.href = window.location.origin;
      }
    }, 1000);
  },

  data() {
    return {
      timeout: 5,
    };
  },
};
</script>

<style scoped>
img {
  fill: white;
}
</style>
