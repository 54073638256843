<template>
  <Dropdown class="is-up">
    <template v-slot:label>
      <div class="dropdown-button is-flex is-align-items-center">
        <i class="fas fa-calculator"></i>
        <p class="is-flex is-flex-direction-column m-0 has-text-left">
          <span>{{ getTerminal.name }}</span>
          <small class="has-text-weight-normal">{{ getTerminal.provider_reference }}</small>
        </p>
      </div>
    </template>

    <a class="dropdown-item" v-for="t in terminals" :key="t.id" @click="selectTerminal(t)">
      <strong>{{ t.name }}</strong> <br />
      <small>{{ t.provider_reference }}</small>
    </a>
  </Dropdown>
</template>

<script>
import Dropdown from "@/components/Dropdown";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TerminalDropdown",
  components: { Dropdown },

  methods: {
    ...mapActions(["selectTerminal"]),
  },

  computed: {
    ...mapGetters(["getTerminal"]),
    terminals() {
      return (this.$store.state.user && this.$store.state.user.terminals) || [];
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-button {
  gap: 1em;
}
</style>
