<template>
  <div class="mini-payment-plan">
    <span class="installments-count"> {{ plan.installments_count }}x </span>

    <span v-if="hasDifferentFirstInstallment" class="first-installment">
      <span class="installment-amount">{{ firstInstallmentAmount | euros }}</span>
      +
    </span>

    <template v-if="identicalInstallmentsCounts > 1">{{ identicalInstallmentsCounts }} x</template>
    <span class="installment-amount">
      {{ nextInstallmentsAmount | euros }}
    </span>
  </div>
</template>

<script>
export default {
  name: "MiniPaymentPlan",
  props: ["plan"],

  computed: {
    hasDifferentFirstInstallment() {
      return (
        this.plan.payment_plan[0].customer_fee !== 0 || this.plan.payment_plan[1].customer_interest !== 0
      );
    },

    identicalInstallmentsCounts() {
      const installmentsCount = this.plan.installments_count;
      return this.hasDifferentFirstInstallment ? installmentsCount - 1 : installmentsCount;
    },

    firstInstallmentAmount() {
      return (
        this.plan.payment_plan[0].purchase_amount +
        this.plan.payment_plan[0].customer_fee +
        this.plan.payment_plan[0].customer_interest
      );
    },

    nextInstallmentsAmount() {
      if (this.plan.payment_plan.length < 2) {
        return 0;
      }

      return (
        this.plan.payment_plan[1].purchase_amount +
        this.plan.payment_plan[1].customer_fee +
        this.plan.payment_plan[1].customer_interest
      );
    },
  },
};
</script>

<style scoped>
.installments-count {
  display: inline-block;
  border-bottom: 3px solid #ff414d;
  margin-right: 10px;
  font-weight: bold;
}

.installment-amount {
}
</style>
