<template>
  <div class="modal" :class="value && `is-active`" @click="$emit('input', false)">
    <div class="modal-background"></div>
    <div class="modal-content" @click.stop="">
      <slot></slot>
    </div>
    <button class="modal-close is-large" aria-label="close"></button>
  </div>
</template>

<script>
export default {
  name: "BulmaModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
$modal-content-width: 900px;
@import "~bulma";
.modal-content {
  background: white;
  padding: 30px;
  text-align: center;
  color: black;
}
</style>
