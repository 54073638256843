<template>
  <Modal
    :hasButtons="displayButtons"
    :canConfirm="canConfirm"
    @confirm="handleConfirmation"
    :canCancel="canCancel"
    @cancel="$emit('cancel')"
  >
    <template v-slot:title
      ><translate :translate-params="{ amount: $options.filters.euros(purchaseAmount) }"
        >Paiement TPE de {{ amount }}</translate
      ></template
    >
    <template v-slot:cancel-label><translate>Annuler</translate></template>
    <template v-slot:ok-label>{{ okButtonLabel }}</template>

    <div v-if="isPending" class="level">
      <div class="level-item has-text-centered my-6">
        <div class="my-4 icon is-size-2"><i class="fas fa-sync-alt fa-spin has-text-info"></i></div>
        <p class="has-text-info" v-translate>Initialisation...</p>
      </div>
    </div>
    <div v-else-if="isLocked">
      <div class="level-item has-text-centered my-4">
        <div class="my-4 icon is-size-2"><i class="far fa-times-circle has-text-danger"></i></div>
        <p class="has-text-danger" v-translate>TPE en cours d'utilisation</p>
      </div>

      <p class="has-text-centered">
        <translate>Il semblerait que votre TPE soit déjà en cours d'utilisation.</translate><br />
      </p>
      <p class="has-text-centered">
        <translate
          ><strong>Si ce n'est pas le cas et que vous avez le TPE en main,</strong> <br />
          vous pouvez le débloquer en cliquant sur "Forcer" ci-dessous.</translate
        >
      </p>
    </div>
    <div v-else class="steps is-size-4">
      <div v-for="(step, idx) in steps" :key="idx" class="step my-5 is-flex">
        <div class="icon mr-4">
          <i :class="stepIconClasses(step)"></i>
        </div>
        <div>
          <p :class="['mb-0', step.status === 'failure' ? 'has-text-danger' : 'has-text-dark']">
            {{ stepTitle(step.code) }}
          </p>
          <p
            v-if="step.status === 'failure' && step.error && step.error.message"
            class="has-text-danger is-size-5 has-text-weight-semibold"
          >
            Erreur : {{ step.error.message }}
          </p>
          <p
            v-else-if="step.status === 'skip' && step.error && step.error.message"
            class="has-text-dark is-size-5 has-text-weight-semibold"
          >
            Info : {{ step.error.message }}
          </p>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  name: "TerminalFlowModal",
  components: { Modal },
  props: ["flowState"],

  methods: {
    stepIconClasses(step) {
      return {
        pending: "far fa-dot-circle has-text-grey",
        ongoing: "fas fa-sync-alt fa-spin has-text-info",
        success: "far fa-check-circle has-text-success",
        failure: "far fa-times-circle has-text-danger",
        skip: "far fa-question-circle has-text-info",
      }[step.status];
    },

    handleConfirmation() {
      if (this.isLocked) {
        this.$emit("forceUnlock");
      } else if (this.isRetryable) {
        this.$emit("retry");
      } else if (this.isSuccess) {
        this.$emit("success");
      }
    },

    is(status) {
      return this.flowState.status === status;
    },

    stepTitle(code) {
      switch (code) {
        case "gateway_connection":
          return this.$gettext("Connexion à la passerelle de paiement");
        case "terminal_ready":
          return this.$gettext("Connexion au TPE");
        case "printer_ready":
          return this.$gettext("Vérification imprimante TPE");
        case "fee_plan_selection":
          return this.$gettext("Choix du nombre d'échéances");
        case "validate_plan":
          return this.$gettext("Validation et signature");
        case "tokenize_card":
          return this.$gettext("Insertion carte bancaire");
        case "scoring":
          return this.$gettext("Acceptation du dossier");
        case "payment":
          return this.$gettext("Paiement");
        case "print_client_receipt":
          return this.$gettext("Impression ticket client");
        case "print_merchant_receipt":
          return this.$gettext("Impression ticket marchand");
        default:
          return this.$gettext("Impossible de determiner l'étape");
      }
    },
  },

  computed: {
    isPending() {
      return this.is("pending");
    },

    isLocked() {
      return this.is("locked");
    },

    isRetryable() {
      return this.is("retry");
    },

    isSuccess() {
      return this.is("success");
    },

    isFailure() {
      return this.is("failure");
    },

    canConfirm() {
      return this.isLocked || this.isRetryable || this.isSuccess;
    },

    canCancel() {
      return !this.isSuccess;
    },

    okButtonLabel() {
      return this.isLocked
        ? this.$gettext("Forcer")
        : this.isSuccess
          ? this.$gettext("Terminer")
          : this.$gettext("Recommencer");
    },

    purchaseAmount() {
      return this.$store.state.currentPayment.purchase_amount;
    },

    steps() {
      return this.flowState.steps;
    },

    displayButtons() {
      return ["pending", "ongoing"].indexOf(this.flowState.status) < 0;
    },
  },
};
</script>

<style scoped>
.level-item {
  flex-direction: column;
}

.fa-spin {
  -webkit-animation: fa-spin 1.5s infinite linear;
  animation: fa-spin 1.5s infinite linear;
}

.step {
  align-items: center;
}
</style>
