/* global AlmaConfig */
window.AlmaConfig = window.AlmaConfig || {
  running_env: "local_dev",
  prod: false,
  api_url: "http://localhost:1337",
  pos_url: "http://localhost:13344",
  dashboard_url: "http://localhost:1338",
  website_url: "https://getalma.eu",
  adyen_gateway_ws_url: "ws://localhost:1441/ws",
  sentry_js_dsn: "",
};

export const RUNNING_ENV = AlmaConfig.running_env;
export const IS_PROD = AlmaConfig.prod;

export const DEFAULT_LOCALE = "en";

export const POS_URL = AlmaConfig.pos_url;
export const API_URL = AlmaConfig.api_url;
export const DASHBOARD_URL = AlmaConfig.dashboard_url;
export const WEBSITE_URL = AlmaConfig.website_url;
export const ADYEN_GATEWAY_WS_URL = AlmaConfig.adyen_gateway_ws_url;
export const SENTRY_JS_DSN = AlmaConfig.sentry_js_dsn;

export const POLLING_TICKS = 5000; /* In milliseconds */
export const POLLING_PAYMENTS_MIN_DELAY = 40; /* In seconds */
export const POLLING_PAYMENTS_MAX_DELAY = 600; /* In seconds */
export const POLLING_EXPORT_MAX_DELAY = 600; /* In seconds */
export const FULL_POLLING_DELAY = 120; /* In seconds */
export const HOTJAR_POS_ID = "2033429";
