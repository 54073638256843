import Vue from "vue";
import Router from "vue-router";
import CreatePaymentView from "./views/CreatePayment";
import SuccessView from "./views/Success";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "create_payment",
      component: CreatePaymentView,
    },
    {
      path: "/success",
      name: "payment_success",
      component: SuccessView,
    },
    {
      path: "*",
      redirect: "/",
    },
  ],
});
