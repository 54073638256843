<template>
  <div
    :class="['dropdown', 'has-text-centered-mobile', { 'is-active': opened }]"
    @click="toggleMenu"
    @mouseleave="closeMenu"
  >
    <a class="dropdown-trigger">
      <button class="button is-white has-text-weight-bold is-flex">
        <span class="dropdown-name is-inline-block">
          <slot name="label">
            {{ buttonLabel }}
          </slot>
        </span>
        <span class="icon is-small"><i class="fas fa-angle-down" aria-hidden="true"></i></span>
      </button>
    </a>

    <div class="dropdown-menu">
      <div class="dropdown-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dropdown",
  props: {
    buttonLabel: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      opened: false,
    };
  },

  methods: {
    toggleMenu() {
      this.opened = !this.opened;
    },

    closeMenu() {
      this.opened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/bulma.scss";

.dropdown-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 260px;
}

.dropdown-content {
  overflow-y: scroll;
  max-height: 300px;
}

.dropdown-trigger .icon {
  vertical-align: super;
}
</style>
