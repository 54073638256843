<template>
  <div v-on:mouseleave="state = 'main'">
    <Dropdown class="is-left locale-selector">
      <template v-slot:label>
        <img src="../assets/language-switcher.png" />
      </template>

      <div>
        <a
          class="locale-item dropdown-item"
          v-for="(name, locale) in locales"
          v-bind:key="locale"
          v-bind:class="{
            'is-active': locale === ($store.state.user.locale || Vue.config.language),
          }"
          v-on:click="changeLocale(locale)"
        >
          <span>{{ name }}</span>
        </a>
      </div>
    </Dropdown>
  </div>
</template>

<script>
import Dropdown from "@/components/Dropdown";

export default {
  name: "LocaleDropdown",
  components: { Dropdown },
  props: ["user"],

  data: function () {
    return {
      locales: {
        de: "Deutsch",
        en: "English",
        es: "Español ",
        fr: "Français",
        it: "Italiano",
        nl: "Nederlands",
      },
    };
  },

  methods: {
    async changeLocale(locale) {
      this.state = "main";
      await this.$store.dispatch("changeLocale", locale);
    },
  },
};
</script>

<style lang="scss">
.locale-selector .dropdown-trigger .button {
  padding: 0px 5px;
}
</style>

<style lang="scss" scoped>
.locale-item {
  display: flex;
  align-items: center;
}
</style>
